import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

export const publicationIcons = {
	authors: <PeopleOutlinedIcon className="detail-icon" style={{ color: "#63A033" }} />,
	venue: <BookOutlinedIcon className="detail-icon" />,
	publicationDate: <CalendarMonthOutlinedIcon className="detail-icon" />
};

export const patentIcons = {
	inventors: <PeopleOutlinedIcon className="detail-icon" style={{ color: "#63A033" }} />,
	publication_number: <BookOutlinedIcon className="detail-icon" />,
	publication_date: <CalendarMonthOutlinedIcon className="detail-icon" />
};

export const grantIcons = {
	award_value: <AccountBalanceWalletOutlinedIcon style={{ color: "#4C90F6" }} className="detail-icon" />,
	AgencyName: <AccountBalanceOutlinedIcon style={{ color: "#AB6EF9" }} className="detail-icon" />,
	// status: <HourglassEmptyIcon className="detail-icon" style={{ color: "#C2B280" }} />,
	CloseDate: <EventAvailableOutlinedIcon className="detail-icon" />
};

export const personIcons = {
	affiliations: <AccountBalanceOutlinedIcon style={{ color: "#AB6EF9" }} className="detail-icon" />
};

export const personUrlIcons = {
	homepage: <img src={`${process.env.PUBLIC_URL}/peopleIcons/website.svg`} alt="X logo" className="detail-icon clickable" />,
	resume: <img src={`${process.env.PUBLIC_URL}/peopleIcons/cv.svg`} alt="resume" className="detail-icon clickable" />,
	LinkedIn: <img src={`${process.env.PUBLIC_URL}/peopleIcons/linkedin.svg`} alt="LinkedIn logo" className="detail-icon clickable" />,
	ResearchGate: <img src={`${process.env.PUBLIC_URL}/peopleIcons/ResearchGate.svg`} alt="ResearchGate logo" className="detail-icon clickable" />,
	GoogleScholar: <img src={`${process.env.PUBLIC_URL}/peopleIcons/googleScholar.svg`} alt="GoogleScholar logo" className="detail-icon clickable" />,
	ORCID: <img src={`${process.env.PUBLIC_URL}/peopleIcons/orcid.svg`} alt="ORCID logo" className="detail-icon clickable" />,
	X: <img src={`${process.env.PUBLIC_URL}/peopleIcons/X.svg`} alt="X logo" className="detail-icon clickable" />,
	organization_url: <InsertLinkOutlinedIcon className="detail-icon clickable" />
};

export const jobIcons = {
	company: <BusinessOutlinedIcon className="detail-icon" />,
	location: <LocationOnOutlinedIcon style={{ color: "#FF6666" }} className="detail-icon" />,
	salary: <AccountBalanceWalletOutlinedIcon style={{ color: "#4C90F6" }} className="detail-icon" />
};

export const organizationIcons = {
	department: <AccountTreeOutlinedIcon className="detail-icon" />,
	location: <LocationOnOutlinedIcon style={{ color: "#FF6666" }} className="detail-icon" />
};

export const eventIcons = {
	location: <LocationOnOutlinedIcon style={{ color: "#FF6666" }} className="detail-icon" />,
	date: <EventAvailableOutlinedIcon className="detail-icon" />,
	ticket_price: <AccountBalanceWalletOutlinedIcon style={{ color: "#4C90F6" }} className="detail-icon" />
};

export const tutorialIcons = {
	author: <PersonOutlinedIcon style={{ color: "#63A033" }} className="detail-icon" />,
	price: <AccountBalanceWalletOutlinedIcon style={{ color: "#4C90F6" }} className="detail-icon" />,
	published_date: <EventAvailableOutlinedIcon className="detail-icon" />
};
