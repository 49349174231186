import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SubmitButton from "./SubmitButton";

const InvitePage = ({ role }) => {
	const { vertical_id } = useParams();
	const [submitting, setSubmitting] = useState(false);
	const [formData, setFormData] = useState({ vertical_id: vertical_id });

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value
		});
	};

	const submitForm = async (e) => {
		e.preventDefault();
		setSubmitting(true);

		const response = await invite(formData);
		if (response.status === 200) {
			toast.success("Invitation sent successfully!", {
				className: "toast",
				autoClose: 3000
			});
		} else {
			toast.error("Unable to invite user! " + response.data.message, {
				className: "toast",
				autoClose: 3000
			});
		}
		setSubmitting(false);
	};

	const invite = async (formData) => {
		let url;
		if (role === "Vertical Editor") {
			url = process.env.REACT_APP_FLASK_WEBSERVER + "inviteVerticalEditor";
		} else {
			url = process.env.REACT_APP_FLASK_WEBSERVER + "inviteUser";
		}

		const res = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(formData)
		});
		const data = await res.json();

		return {
			status: res.status,
			data: data
		};
	};

	useEffect(() => {
		document.title = "Invite " + role + " - The Simple Platform";
	}, []);

	return (
		<div style={{ height: "70vh", display: "flex", alignItems: "center" }}>
			<form onSubmit={submitForm} className="add-form">
				<h2 className="heading-style">Invite A {role}</h2>

				<div style={{ textAlign: "left" }}>
					<div>
						<label className="label-style">
							<span>Email</span>
							<span style={{ color: "red" }}>&nbsp;*</span>
						</label>
						<input type="email" id="email" name="email" className="input-style" placeholder={role === "Vertical Editor" ? "paul@researchnetai.com" : "paul@gmail.com"} required="true" autoComplete="new-email" onChange={handleChange} />
					</div>

					<div>
						<label className="label-style">
							<span>Name</span>
							<span style={{ color: "red" }}>&nbsp;*</span>
						</label>
						<input type="text" id="name" name="name" className="input-style" placeholder="Paul Galleberg" onChange={handleChange} />
					</div>
				</div>

				<div>
					<SubmitButton text={`Invite ${role}`} disabled={submitting} width="30%" height="36px" margin="10px" fontWeight={400} fontSize="14px" />
					<Button type="reset" variant="outlined" disableRipple={true}>
						Reset
					</Button>
				</div>
			</form>
		</div>
	);
};
export default InvitePage;
