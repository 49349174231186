import { useState } from "react";
import styles from "./Auth.module.css";
import { validateEmail } from "../../utils/functions";
import { toast } from "react-toastify";
import SubmitButton from "./SubmitButton";

const ForgotPassword = ({ email_ }) => {
	const [email, setEmail] = useState(email_);
	const [validEmail, setValidEmail] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const onSubmit = async (e) => {
		e.preventDefault();
		setSubmitting(true);

		const res = await fetch(process.env.REACT_APP_FLASK_WEBSERVER + "sendPasswordResetEmail", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({ email: email })
		});

		if (res.ok) {
			displayToast(email);
			setSubmitted(true);
		}
		setSubmitting(false);
	};

	const displayToast = (email) => {
		toast.success(
			<>
				Check your inbox, we sent you a reset password e-mail at {email}
				<br></br> If you don't see the email in your inbox, please check your spam or junk folder.
			</>,
			{
				className: styles.toast,
				autoClose: 3000,
				position: "top-center",
				hideProgressBar: true,
				closeButton: false,
				icon: false,
				theme: "dark"
			}
		);
	};

	const handleChange = async (e) => {
		setEmail(e.target.value);
		setValidEmail(validateEmail(e.target.value));
	};

	return (
		<div style={{ width: "400px" }}>
			<h2>Reset your password</h2>
			<p style={{ fontWeight: "500", fontSize: "16px" }}>Enter the email address you used to sign up and we’ll send you instructions to reset your password.</p>
			<form onSubmit={onSubmit}>
				<p className={styles["input-label"]}>Your e-mail</p>
				<div>
					<input type="email" className={`${styles["input-email"]}`} placeholder="e.g. john.doe@example.com" required value={email} onChange={handleChange} />
				</div>
				<div style={{ paddingTop: "20px" }}>
					<SubmitButton text="Reset password" disabled={!validEmail || submitted || submitting} disabledText={!validEmail || submitted} />
				</div>
			</form>
			{submitted && (
				<p style={{ fontSize: "16px", color: "#596274" }}>
					Didn’t receive the email?&nbsp;
					<span className={styles.skipButton} style={{ fontWeight: "700", color: "black" }} onClick={onSubmit}>
						Click to resend.
					</span>
				</p>
			)}
		</div>
	);
};

export default ForgotPassword;
