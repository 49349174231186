const WhatsComing = ({ category }) => {
	let text, image;
	if (category === "jobs") {
		text =
			"TSP will offer a feature connecting talent with those needing talent—that might be a full-time job, a part-time gig, a lab position, a speaker with a relevant conference... whatever is relevant to you, to your interests and to your vertical. While we are in this phase of development, we welcome your suggestions as to what content you would find useful.";
		image = "jobs.svg";
	} else if (category === "organizations") {
		text = "TSP will offer a feature listing organizations relevant to you, to your interests and to your vertical. While we are in this phase of development, we welcome your suggestions as to what content you would find useful.";
		image = "organizations.svg";
	} else if (category === "events") {
		text =
			"TSP will offer a feature listing events relevant to you, to your interests and to your vertical. That might be a conference, a call for papers, an online lecture...whatever is relevant to you, to your interests and to your vertical. While we are in this phase of development, we welcome your suggestions as to what content you would find useful.";
		image = "events.svg";
	} else if (category === "tutorials") {
		text =
			"TSP will offer features offering free learning modules, some that are relevant to everyone and shown on the TSP platform level with others being relevant only to your vertical and shown only on that vertical. While we are in this phase of development, we welcome your suggestions as to what content you would find useful.";
		image = "tutorials.svg";
	}
	return category === "jobs" || category === "organizations" || category === "events" || category === "tutorials" ? (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				alignItems: "flex-start"
			}}>
			<p style={{ width: "50%" }}>{text}</p>
			<img src={`${process.env.PUBLIC_URL}/whatsComing/${image}`} style={{ alignSelf: "flex-end", marginTop: "50px" }} />
		</div>
	) : (
		<p>
			<i>No contents to display</i>
		</p>
	);
};
export default WhatsComing;
