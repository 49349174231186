import SearchIcon from "@mui/icons-material/Search";
import "./SearchBar.css";
import { useState, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Button } from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";

const SearchBar = ({ verticalEditor, isActive, width = "30%", height = "60%" }) => {
	const [isFocused, setIsFocused] = useState(false);
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const query = searchParams.get("q");
	const [searchTerm, setSearchTerm] = useState("");
	const { vertical_id, query_id } = useParams();
	const navigate = useNavigate();

	const handleSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();
		//use verticalEditor to construct proper URL dynamically
		if (verticalEditor) {
			navigate(`/ve/vertical/${vertical_id}/search?q=${searchTerm}`);
		} else if (!query_id) {
			navigate(`/u/vertical/${vertical_id}/search?q=${searchTerm}`);
		} else {
			navigate(`/u/vertical/${vertical_id}/query/${query_id}/search?q=${searchTerm}`);
		}
	};

	useEffect(() => {
		if (isActive) setSearchTerm("");
		else setSearchTerm(query || "");
	}, [location.pathname, query]);

	const clearInput = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setSearchTerm("");
	};

	return (
		<div className={`search-box ${isFocused || isActive ? "focused" : ""}`} style={{ width: width, height: height, marginLeft: "40px", flexGrow: 1 }}>
			<SearchIcon style={{ padding: "8px" }} />
			<input
				className="search-input"
				placeholder="Search here"
				type="text"
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
				onFocus={() => setIsFocused(true)}
				onBlur={(e) => {
					if (!e.relatedTarget || !e.relatedTarget.classList.contains("clear")) {
						setIsFocused(false);
					}
				}}
				onKeyDown={(e) => e.key === "Enter" && searchTerm.length > 0 && handleSubmit(e)}
			/>
			{searchTerm.length > 0 && (
				<>
					<button className="clear" onClick={(e) => clearInput(e)}>
						<ClearIcon className="clear" style={{ margin: "8px" }} />
					</button>
					{isFocused && (
						<Button variant="contained" sx={{ textTransform: "none", margin: "8px", height: "60%" }} onMouseDown={(e) => handleSubmit(e)}>
							Search
						</Button>
					)}
				</>
			)}
		</div>
	);
};
export default SearchBar;
