import FormWithImage from "../../components/Forms/FormWithImage";
import { invalidUrlError, validateUrl } from "../../utils/functions";

const InventorForm = () => {
	const category = "inventor";
	const fields = [
		{ name: "name", label: "Name", type: "text", disabled: true },
		{ name: "affiliations", label: "Affiliations", type: "text" },
		{ name: "department", label: "Department", type: "text" },
		{ name: "program", label: "Program", type: "text" },
		{ name: "about", label: "About", type: "text", text_area: true },
		{ name: "patentCount", label: "Patent Count", type: "number" },
		{ name: "homepage", label: "Homepage URL", type: "text", placeholder: "https://profiles.stanford.edu/jackson", pattern: validateUrl, title: invalidUrlError },
		{ name: "email", label: "Email", type: "email", placeholder: "Rob.Jackson@stanford.edu" },
		{ name: "resume", label: "Resume", type: "text", placeholder: "https://cap.stanford.edu/profiles/viewCV?facultyId=48949&name=Rob_Jackson", pattern: validateUrl, title: invalidUrlError },
		{ name: "LinkedIn", label: "LinkedIn", type: "text", placeholder: "https://www.linkedin.com/in/rob-jackson/", pattern: validateUrl, title: invalidUrlError },
		{ name: "ResearchGate", label: "ResearchGate", type: "text", placeholder: "https://www.researchgate.net/profile/Robert-Jackson-14", pattern: validateUrl, title: invalidUrlError },
		{ name: "GoogleScholar", label: "Google Scholar", type: "text", placeholder: "https://scholar.google.com/citations?user=G3NBUzwAAAAJ&hl=en", pattern: validateUrl, title: invalidUrlError },
		{ name: "ORCID", label: "ORCID", type: "text", placeholder: "https://orcid.org/0000-0001-8846-7147", pattern: validateUrl, title: invalidUrlError },
		{ name: "X", label: "X (formerly Twitter)", type: "text", placeholder: "https://twitter.com/robajackson?lang=en", pattern: validateUrl, title: invalidUrlError },
		{ name: "organization_url", label: "Organization URL", type: "text", placeholder: "https://jacksonlab.stanford.edu/", pattern: validateUrl, title: invalidUrlError },
		{ name: "image_url", label: "Image", type: "image" }
	];

	return <FormWithImage category={category} fields={fields} />;
};
export default InventorForm;
