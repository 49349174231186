import Password from "./Password";
import styles from "./Auth.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { confirmPasswordReset } from "firebase/auth";
import auth from "../../firebase";
import SubmitButton from "./SubmitButton";
import ErrorPage from "./ErrorPage";

const SetNewPasswordPage = () => {
	const queryParams = new URLSearchParams(window.location.search);
	const oobCode = queryParams.get("oobCode");
	console.log("oobCode: ", oobCode);

	const navigate = useNavigate();
	const [isFormValid, setIsFormValid] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [samePassword, setSamePassword] = useState(true);
	const [invalidLink, setInvalidLink] = useState(false);

	const [formData, setFormData] = useState({
		password: "",
		repeatPassword: ""
	});

	const onSubmit = async (e) => {
		e.preventDefault();

		setSubmitting(true);

		try {
			await confirmPasswordReset(auth, oobCode, formData.password);
			console.log("Password reset successful");
			navigate("/password-changed");
		} catch (error) {
			console.log("HREE");
			console.error("Error resetting password:", error);
			setInvalidLink(true);
		}

		setSubmitting(false);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value
		});

		validateForm({ ...formData, [name]: value });
	};

	const validateForm = (values) => {
		const { password, repeatPassword } = values;
		const isValid = password.length >= 8 && password === repeatPassword;
		setIsFormValid(isValid);

		if (repeatPassword.length > 0 && password !== repeatPassword) {
			setSamePassword(false);
		} else {
			setSamePassword(true);
		}
	};

	return !invalidLink ? (
		<div style={{ width: "320px" }}>
			<h2>Enter new password</h2>
			<form onSubmit={onSubmit}>
				<Password name="password" label="Your new password" password={formData.password} onChange={handleChange} pattern={true} />
				<p className={styles.roleDescription}>At least 8 characters, 1 capital, 1 symbol, 1 number.</p>

				<Password name="repeatPassword" label="Repeat new password" password={formData.repeatPassword} onChange={handleChange} error={!samePassword} />
				{!samePassword && <p className={styles.error}>The password doesn’t match.</p>}

				<div style={{ paddingTop: "20px" }}>
					<SubmitButton text="Reset password" disabled={!isFormValid || submitting} disabledText={!isFormValid} />
				</div>
			</form>
		</div>
	) : (
		<ErrorPage text="password reset" />
	);
};

export default SetNewPasswordPage;
