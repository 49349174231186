import { publicationIcons, personIcons, personUrlIcons, grantIcons, patentIcons, jobIcons, organizationIcons, eventIcons, tutorialIcons } from "./cardIcons";

export const categories = {
	papers: {
		cardHeader: "PUBLICATION",
		icons: publicationIcons,
		dotColor: "#FF8832"
	},
	patents: {
		cardHeader: "PATENT",
		icons: patentIcons,
		dotColor: "#5BA8A0"
	},
	grants: {
		cardHeader: "GRANT",
		icons: grantIcons,
		dotColor: "#4C90F6"
	},
	authors: {
		cardHeader: "AUTHOR",
		icons: personIcons,
		dotColor: "#63A033",
		urlIcons: personUrlIcons
	},
	inventors: {
		cardHeader: "INVENTOR",
		icons: personIcons,
		dotColor: "#63A033",
		urlIcons: personUrlIcons
	},
	people: {
		icons: personIcons,
		dotColor: "#63A033",
		urlIcons: personUrlIcons
	},
	jobs: {
		cardHeader: "JOB",
		icons: jobIcons,
		dotColor: "#F94A93"
	},
	organizations: {
		cardHeader: "ORGANIZATION",
		icons: organizationIcons,
		dotColor: "#AB6EF9"
	},
	events: {
		cardHeader: "EVENT",
		icons: eventIcons,
		dotColor: "#09B899"
	},
	tutorials: {
		cardHeader: "TUTORIAL",
		icons: tutorialIcons,
		dotColor: "#F16D6D"
	}
};
