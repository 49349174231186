const ErrorPage = ({ text }) => {
	return (
		<div style={{ display: "flex", alignItems: "center", textAlign: "left" }}>
			<div style={{ flexBasis: "35%", margin: "100px" }}>
				<strong>Oops! Your {text} link is invalid or has expired.</strong>
				<p>It looks like the link you’re trying to use is no longer valid. This might happen if the link has expired or has already been used.</p>
				<p>Please request a new signup link or contact support if you believe this is a mistake.</p>
			</div>
			<img style={{ flexBasis: "40%", flexGrow: 0, margin: "25px" }} src={`${process.env.PUBLIC_URL}/404.svg`} alt="404" />
		</div>
	);
};
export default ErrorPage;
