import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import styles from "./Auth.module.css";
import { useState } from "react";

const Password = ({ label = "Password", name = "password", password, onChange, error = false, pattern = false, placeholder = "Type here" }) => {
	const [pwdVisible, setPwdVisible] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	return (
		<>
			<p className={styles["input-label"]}>{label}</p>
			<div className={`${styles.password} ${isFocused ? styles["input-focused"] : ""}  ${error ? styles["input-error"] : ""}`}>
				<input
					className={`${styles["input-password"]} ${styles["input-icon"]}`}
					type={pwdVisible ? "text" : "password"}
					placeholder={placeholder}
					autoComplete="new-password"
					required
					value={password}
                    name={name}
					pattern={pattern && "(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}"}
					title={pattern && "Password must be at least 8 characters long, and include at least one uppercase letter, one lowercase letter, one number, and one special character."}
					onChange={(e) => onChange(e)}
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
				/>
				{pwdVisible ? (
					<VisibilityOutlinedIcon
						style={{ padding: "8px", width: "16px", height: "16px" }}
						onClick={() => {
							setPwdVisible((prevState) => !prevState);
						}}
					/>
				) : (
					<VisibilityOffOutlinedIcon
						style={{ padding: "8px", width: "16px", height: "16px" }}
						onClick={() => {
							setPwdVisible((prevState) => !prevState);
						}}
					/>
				)}
			</div>
		</>
	);
};

export default Password;
