import { useNavigate, useLocation } from "react-router-dom";
import styles from "./Auth.module.css";
import SubmitButton from "./SubmitButton";
import auth from "../../firebase";
import { useState } from "react";
import { roles } from "./roles";

const SelectRolePage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const user = auth.currentUser;
	const [submitting, setSubmitting] = useState(false);

	const { verticalJoined } = location.state || {};

	const onSubmit = async (e) => {
		e.preventDefault();
		setSubmitting(true);
		const formData = new FormData(e.target);
		const selectedRole = formData.get("role");

		const url = process.env.REACT_APP_FLASK_WEBSERVER + "edit_user?uid=" + user.uid;
		await fetch(url, {
			method: "PATCH",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({ role: selectedRole })
		});
		setSubmitting(false);

		navigate("/select-sub-role", {
			state: {
				role: selectedRole,
				verticalJoined: verticalJoined
			}
		});
	};

	return (
		<div>
			<h2>Select your primary role</h2>
			<p style={{ fontWeight: "500", fontSize: "20px" }}>to ensure your best experience using The Simple Platform</p>
			<form onSubmit={onSubmit} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", width: "50%", margin: "0 auto" }}>
				<p style={{ fontWeight: "700", fontSize: "16px", position: "relative", left: "30px" }}>My role better described as:</p>
				{Object.entries(roles).map(([role, description], index) => (
					<div className={styles.role}>
						<input type="radio" id={role} name="role" value={role} defaultChecked={index === 0}></input>
						<div>
							<label for={role}>
								{role}
								<p className={styles.roleDescription}>{description}</p>
							</label>
						</div>
					</div>
				))}
				<div className={styles.footer}>
					<SubmitButton text="Continue" width="25%" height="48px" disabled={submitting} />
				</div>
			</form>
		</div>
	);
};
export default SelectRolePage;
