import { signInWithEmailAndPassword } from "firebase/auth";
import auth from "../../firebase";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./Auth.module.css";
import Password from "./Password";
import SubmitButton from "./SubmitButton";
import ErrorPage from "./ErrorPage";
import Spinner from "./Spinner";

const SignUpPage = () => {
	const [samePassword, setSamePassword] = useState(true);
	const [weakPwd, setWeakPwd] = useState(false);
	const [emailExists, setEmailExists] = useState(false);
	const [verticalJoined, setVerticalJoined] = useState(null);
	const [formData, setFormData] = useState({ firstName: "", lastName: "", password: "", repeatPassword: "", checkbox: false });
	const [isFormValid, setIsFormValid] = useState(false);
	const [isChecked, setIsChecked] = useState(false);
	const [loading, setLoading] = useState(true);

	const [submitting, setSubmitting] = useState(false);

	const navigate = useNavigate();

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const token = searchParams.get("token");

	const [validToken, setValidToken] = useState();

	useEffect(() => {
		const verifyToken = async () => {
			const url = process.env.REACT_APP_FLASK_WEBSERVER + `verifyToken?token=${token}`;

			const res = await fetch(url);
			const data = await res.json();
			if (res.status === 200) {
				console.log("data: ", data);
				setValidToken(true);
				setFormData({
					...formData,
					email: data["email"],
					vertical_joined: data["vertical_id"],
					firstName: data["name"] ? data["name"].split(" ")[0] : "",
					lastName: data["name"] && data["name"].split(" ").length > 1 ? data["name"].split(" ")[data["name"].split(" ").length - 1] : ""
				});
				setVerticalJoined(data["vertical_id"]);
			} else {
				setValidToken(false);
			}
			setLoading(false);
		};
		verifyToken();
	}, [token]);

	const onSubmit = async (e) => {
		e.preventDefault();
		setSubmitting(true);

		try {
			// Create user
			const res = await fetch(process.env.REACT_APP_FLASK_WEBSERVER + `createUser?token=${token}`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({ ...formData })
			});

			if (res.ok) {
				await signInWithEmailAndPassword(auth, formData.email, formData.password);
				navigate("/select-role", {
					state: { verticalJoined: verticalJoined }
				});
			} else {
				setEmailExists(true);
			}
		} catch (error) {}
		setSubmitting(false);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value
		});

		validateForm({ ...formData, [name]: value });
	};

	const validateForm = (values) => {
		const { firstName, lastName, password, repeatPassword } = values;
		const isValid = firstName.trim() !== "" && lastName.trim() !== "" && password.length >= 8 && password === repeatPassword;
		setIsFormValid(isValid);

		if (repeatPassword.length > 0 && password !== repeatPassword) {
			setSamePassword(false);
		} else {
			setSamePassword(true);
		}
	};

	return loading === false ? (
		validToken === true ? (
			<div style={{ width: "320px" }}>
				<h2>Signup</h2>

				<form onSubmit={onSubmit}>
					<p className={styles["input-label"]}>Sign up with:</p>
					<div>
						<input className={`${styles["input-email"]}`} style={{ color: "#717782" }} type="email" name="email" autoComplete="off" required value={formData.email} disabled={true} />
					</div>

					<p className={styles["input-label"]}>First name</p>
					<div>
						<input className={`${styles["input-text"]}`} type="text" name="firstName" value={formData.firstName} placeholder="Your first name" autoComplete="off" required onChange={handleChange} />
					</div>

					<p className={styles["input-label"]}>Last name</p>
					<div>
						<input className={`${styles["input-text"]}`} type="text" name="lastName" value={formData.lastName} placeholder="Your last name" autoComplete="off" required onChange={handleChange} />
					</div>

					<Password name="password" password={formData.password} onChange={handleChange} pattern={true} />
					<p className={styles.roleDescription}>At least 8 characters, 1 capital, 1 symbol, 1 number.</p>

					<Password label={"Repeat Password"} name="repeatPassword" password={formData.repeatPassword} onChange={handleChange} error={!samePassword} />
					{!samePassword && <p className={styles.error}>The password doesn’t match.</p>}

					<div style={{ paddingTop: "10px", display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
						<input type="checkbox" name="checkbox" className={styles["checkbox"]} onChange={() => setIsChecked((prevValue) => !prevValue)} />
						<label className={styles["checkbox-label"]}>
							I agree with The Simple platform’s{" "}
							<a href="https://www.termsfeed.com/blog/sample-terms-of-use-template/" target="_blank">
								Terms and Conditions
							</a>
							&nbsp;and&nbsp;
							<a href="https://www.freeprivacypolicy.com/blog/privacy-policy-url/" target="_blank">
								Privacy Policy
							</a>
						</label>
					</div>

					{weakPwd && <p className={styles.error}>Password should be at least 6 characters</p>}

					<div style={{ paddingTop: "20px" }}>
						<SubmitButton text={"Continue"} disabled={submitting || !isFormValid || !isChecked || emailExists} disabledText={!isFormValid || !isChecked || emailExists} />
					</div>
					{emailExists && <p className={styles.error}>Email already in use. </p>}
				</form>
			</div>
		) : (
			<ErrorPage text="signup" />
		)
	) : (
		<Spinner />
	);
};

export default SignUpPage;
