import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import PinOutlinedIcon from "@mui/icons-material/PinOutlined";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import Markdown from "react-markdown";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useCategory } from "../../CategoryContext";

import BackButton from "../../components/BackButton";

import auth from "../../firebase";

const GrantPage = ({ category = "grant" }) => {
	const { updateCount } = useCategory();
	const user = auth.currentUser;

	const [item, setItem] = useState({});
	const [verticalCreator, setVerticalCreator] = useState({});
	const [loading, setLoading] = useState(true);
	const { vertical_id, id } = useParams();
	const [show, setShow] = useState();
	const navigate = useNavigate();
	const label = { inputProps: { "aria-label": "Switch demo" } };
	const theme = useTheme();

	const activeButtonStyle = {
		fontSize: "16px", // Increase font size
		margin: "10px",
		width: "100%",
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
			boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" // Adding slight shadow
		}
	};

	useEffect(() => {
		const getItem = async () => {
			const item = await fetchItem();
			setItem(item.data);
			document.title = item.data.OpportunityTitle + " - The Simple Platform";
			setShow(item.data.show);
			setVerticalCreator(item.verticalCreator);
			setLoading(false);
		};
		getItem();
	}, []);

	const fetchItem = async () => {
		const token = await user.getIdToken();

		const url = process.env.REACT_APP_FLASK_WEBSERVER + "get_" + category + "_details?id=" + id + "&vertical_id=" + vertical_id;
		const res = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
		const data = await res.json();
		return data;
	};

	function editContent(event) {
		event.stopPropagation();
		navigate("edit");
	}

	const hideContent = async (event) => {
		event.stopPropagation();

		const isChecked = event.target.checked;
		if (isChecked) {
			updateCount(category + "s", 1);
		} else {
			updateCount(category + "s", -1);
		}
		setShow(isChecked);

		const res = await fetch(process.env.REACT_APP_FLASK_WEBSERVER + "change_visibility?id=" + item._id + "&collection=" + category + "s", {
			method: "PATCH",
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify({ show: isChecked })
		});
		await res.json();
	};

	return loading ? (
		<div className="container">
			<FontAwesomeIcon icon={faSpinner} spin size="10x" color="whitesmoke"></FontAwesomeIcon>
		</div>
	) : (
		<div style={{ width: "75%", textAlign: "left" }}>
			<BackButton />
			{item?.grant_types && <h4 style={{ color: "#596274" }}>{item.grant_types.toUpperCase()}</h4>}

			<div className="detail-card">
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					{item?.OpportunityTitle && <h2 className="detail-title">{item.OpportunityTitle}</h2>}

					{verticalCreator && (
						<div style={{ display: "flex", alignItems: "center", padding: 0, margin: 0 }}>
							<FontAwesomeIcon icon={faPenToSquare} className="detail-icon clickable" onClick={(event) => editContent(event)}></FontAwesomeIcon>
							<Switch {...label} checked={show} onClick={(event) => hideContent(event)} />
						</div>
					)}
				</div>

				<div className="detail-item" style={{ border: "1px solid #356AC3", borderRadius: "4px", color: "#356AC3", fontWeight: 700, display: "flex", width: "250px" }}>
					<EventAvailableOutlinedIcon className="detail-icon-" />
					<span>Deadline: {item?.CloseDate ? item.CloseDate : "Continuous"}</span>
				</div>

				{item?.status && (
					<div className="detail-item">
						{{
							open: <HourglassTopIcon className="detail-icon-" style={{ color: "#C2B280" }} />,
							forecasted: <HourglassEmptyIcon className="detail-icon-" style={{ color: "#C2B280" }} />,
							closed: <HourglassBottomIcon className="detail-icon-" style={{ color: "#C2B280" }} />
						}[item.status] || <HourglassTopIcon style={{ color: "#C2B280" }} />}

						<span>
							{{
								open: "Accepting Applications",
								forecasted: "Forecasted Grant",
								closed: "Grant Closed"
							}[item.status] || "Status Unknown"}
						</span>
					</div>
				)}

				{item?.AgencyName && (
					<div className="detail-item">
						<AccountBalanceOutlinedIcon className="detail-icon-" style={{ color: "#AB6EF9" }} />
						<span>{item.AgencyName}</span>
					</div>
				)}

				{(item?.AwardCeiling || item?.AwardFloor || item?.ExpectedNumberOfAwards || item?.EstimatedTotalProgramFunding) && (
					<div className="detail-item">
						<AccountBalanceWalletOutlinedIcon className="detail-icon-" style={{ color: "#4C90F6" }} />
						<div style={{ flexWrap: "wrap" }}>
							{item?.AwardCeiling && <span>Award Ceiling {item.AwardCeiling}&nbsp;&#x2022;&nbsp;</span>}
							{item?.AwardFloor && <span>Award Floor {item.AwardFloor}&nbsp;&#x2022;&nbsp;</span>}
							{(item?.ExpectedNumberOfAwards || item?.ExpectedNumberOfAwards === 0) && <span>Expected Number Of Awards {item.ExpectedNumberOfAwards}&nbsp;&#x2022;&nbsp;</span>}
							{item?.EstimatedTotalProgramFunding && <span>Estimated Total Program Funding {item.EstimatedTotalProgramFunding}&nbsp;&#x2022;&nbsp;</span>}
						</div>
					</div>
				)}

				{(item?.PostDate || item?.EstimatedSynopsisPostDate || item?.EstimatedSynopsisCloseDate || item?.LastUpdatedDate || item?.ArchiveDate || item?.EstimatedAwardDate || item?.EstimatedProjectStartDate) && (
					<div className="detail-item">
						<CalendarMonthOutlinedIcon className="detail-icon-" />
						<div style={{ flexWrap: "wrap" }}>
							{item?.PostDate && <span>Post Date {item.PostDate}&nbsp;&#x2022;&nbsp;</span>}
							{item?.EstimatedSynopsisPostDate && <span>Estimated Synopsis Post Date {item.EstimatedSynopsisPostDate}&nbsp;&#x2022;&nbsp;</span>}
							{item?.EstimatedSynopsisCloseDate && <span>Estimated Synopsis Close Date {item.EstimatedSynopsisCloseDate}&nbsp;&#x2022;&nbsp;</span>}
							{item?.LastUpdatedDate && <span>Last Updated Date {item.LastUpdatedDate}&nbsp;&#x2022;&nbsp;</span>}
							{item?.ArchiveDate && <span>Archive Date {item.ArchiveDate}&nbsp;&#x2022;&nbsp;</span>}
							{item?.EstimatedAwardDate && <span>Estimated Award Date {item.EstimatedAwardDate}&nbsp;&#x2022;&nbsp;</span>}
							{item?.EstimatedProjectStartDate && <span>Estimated Project Start Date {item.EstimatedProjectStartDate}&nbsp;&#x2022;&nbsp;</span>}
						</div>
					</div>
				)}

				{item?.activity_locations && (
					<div className="detail-item">
						<LocationOnOutlinedIcon className="detail-icon-" style={{ color: "#FF6666" }} />
						<span>{item.activity_locations}</span>
					</div>
				)}

				{item?.AdditionalInformationURL && (
					<div className="detail-item grant">
						<InsertLinkOutlinedIcon className="detail-icon-" style={{ color: "#4C90F6" }} />
						<a href={item.AdditionalInformationURL.startsWith("http://") || item.AdditionalInformationURL.startsWith("https://") ? item.AdditionalInformationURL : `https://${item.AdditionalInformationURL}`} target="_blank">
							Additional Information URL
						</a>
					</div>
				)}

				{item?.GrantorContactName && (
					<div className="detail-item">
						<BadgeOutlinedIcon className="detail-icon-" style={{ color: "" }} />
						<span>{item.GrantorContactName}</span>
					</div>
				)}

				{item?.GrantorContactEmail && (
					<div className="detail-item">
						<AlternateEmailOutlinedIcon className="detail-icon-" style={{ color: "" }} />
						<span>{item.GrantorContactEmail}</span>
					</div>
				)}

				{item?.GrantorContactPhoneNumber && (
					<div className="detail-item">
						<LocalPhoneOutlinedIcon className="detail-icon-" style={{ color: "" }} />
						<span>{item.GrantorContactPhoneNumber}</span>
					</div>
				)}

				{item?.GrantorContactText && (
					<div className="detail-item">
						<BadgeOutlinedIcon className="detail-icon-" style={{ color: "" }} />
						<span>{item.GrantorContactText}</span>
					</div>
				)}

				{item?.CFDANumbers && (
					<div className="detail-item">
						<PinOutlinedIcon className="detail-icon-" style={{ color: "" }} />
						<span>CFDA Numbers: {item.CFDANumbers}</span>
					</div>
				)}

				{item?.grant_source_url && (
					<Button
						variant="outlined"
						disableRipple={true}
						sx={activeButtonStyle}
						onClick={() => {
							const url = item.grant_source_url;
							const prefixedUrl = url.startsWith("http://") || url.startsWith("https://") ? url : `https://${url}`;
							window.open(prefixedUrl, "_blank");
						}}>
						Apply for Funding
					</Button>
				)}

				{item?.Description && (
					<div style={{ padding: "0 10px" }}>
						<h2 className="detail-title" style={{ fontSize: "22px" }}>
							Description
						</h2>
						{item?.source === "GrantForward" ? (
							<p style={{ margin: 0, textAlign: "justify" }}>
								<Markdown>{item.Description}</Markdown>
							</p>
						) : (
							<div style={{ margin: 0, textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: item.Description }}></div>
						)}
						<br></br>
					</div>
				)}

				{(item?.EligibleApplicants || item?.AdditionalInformationOnEligibility || item?.applicant_locations || item?.applicant_types) && (
					<div style={{ padding: "0 10px" }}>
						<h2 className="detail-title" style={{ fontSize: "22px" }}>
							Eligibility
						</h2>
					</div>
				)}

				{item?.EligibleApplicants && (
					<div style={{ padding: "0 10px" }}>
						<p style={{ margin: 0, textAlign: "justify" }}>Eligible Applicants: {item.EligibleApplicants}</p>
						<br></br>
					</div>
				)}

				{item?.applicant_locations && (
					<div style={{ padding: "0 10px" }}>
						<p style={{ margin: 0, textAlign: "justify", fontWeight: "700" }}>Applicant Locations: {item.applicant_locations}</p>
						<br></br>
					</div>
				)}

				{item?.applicant_types && (
					<div style={{ padding: "0 10px" }}>
						<p style={{ margin: 0, textAlign: "justify", fontWeight: "700" }}>Applicant Types: {item.applicant_types}</p>
						<br></br>
					</div>
				)}

				{item?.AdditionalInformationOnEligibility && (
					<div style={{ padding: "0 10px" }}>
						{item?.source === "GrantForward" ? (
							<p style={{ margin: 0, textAlign: "justify" }}>
								<Markdown>{item.AdditionalInformationOnEligibility}</Markdown>
							</p>
						) : (
							<div style={{ margin: 0, textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: item.AdditionalInformationOnEligibility }}></div>
						)}
						<br></br>
					</div>
				)}

				{item?.submission_info && (
					<div style={{ padding: "0 10px" }}>
						<h2 className="detail-title" style={{ fontSize: "22px" }}>
							Submission Information
						</h2>
						{item?.source === "GrantForward" ? (
							<p style={{ margin: 0, textAlign: "justify" }}>
								<Markdown>{item.submission_info}</Markdown>
							</p>
						) : (
							<div style={{ margin: 0, textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: item.submission_info }}></div>
						)}
						<br></br>
					</div>
				)}

				{item?.amount_info && (
					<div style={{ padding: "0 10px" }}>
						<h2 className="detail-title" style={{ fontSize: "22px" }}>
							Amount Information
						</h2>
						{item?.source === "GrantForward" ? (
							<p style={{ margin: 0, textAlign: "justify" }}>
								<Markdown>{item.amount_info}</Markdown>
							</p>
						) : (
							<div style={{ margin: 0, textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: item.amount_info }}></div>
						)}
						<br></br>
					</div>
				)}

				{item?.support_info && (
					<div style={{ padding: "0 10px" }}>
						<h2 className="detail-title" style={{ fontSize: "22px" }}>
							Support Information
						</h2>
						<div style={{ margin: 0, textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: item.support_info }}></div>
						<br></br>
					</div>
				)}
			</div>
		</div>
	);
};
export default GrantPage;
