import SearchBar from "../components/SearchBar/SearchBar";
import { useState, useEffect } from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams, useLocation } from "react-router-dom";
import Card from "../components/Card/Card";
import { categories } from "../utils/cardInfo";
import auth from "../firebase";

const SearchPage = () => {
	const user = auth.currentUser;
	const [loading, setLoading] = useState(true);
	const { vertical_id, query_id } = useParams();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const query = searchParams.get("q"); // Get the value of 'q'
	const [searchResults, setSearchResults] = useState({});
	const [verticalEditor, setVerticalEditor] = useState(false);
	const totalCategories = Object.keys(searchResults).length;
	const totalResults = Object.keys(searchResults).reduce((total, key) => {
		return total + searchResults[key].length;
	}, 0);

	useEffect(() => {
		const getSearchResults = async () => {
			const fetchedResults = await fetchSearchResults();
			console.log("fetchedResults: ", fetchedResults);
			setSearchResults(fetchedResults.results);
			setVerticalEditor(fetchedResults.verticalCreator);

			document.title = "Search Results - The Simple Platform";
			setLoading(false);
		};
		getSearchResults();
	}, [query]);

	const fetchSearchResults = async () => {
		const token = await user.getIdToken();
		const url = process.env.REACT_APP_FLASK_WEBSERVER + `get_search_results?vertical_id=${!query_id ? vertical_id : query_id}&query=${query}`;
		console.log("url: ", url);
		const res = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
		const data = await res.json();
		return data;
	};
	return loading ? (
		<div className="container">
			<FontAwesomeIcon icon={faSpinner} spin size="10x" color="whitesmoke"></FontAwesomeIcon>
		</div>
	) : Object.keys(searchResults).length === 0 ? (
		<div className="" style={{ textAlign: "left", width: "600px", marginLeft: "250px" }}>
			<img src={`${process.env.PUBLIC_URL}/scroll.svg`} alt="scroll" />
			<p>Oops! We couldn't find any results matching your search. Here are a few suggestions to help you find what you're looking for:</p>
			<ol>
				<li>Try different keywords or refine your search terms.</li>
				<li>Explore our popular categories to discover trending items.</li>
			</ol>
			<p>We're constantly updating our platform, so check back soon for new content and exciting additions. Happy searching!</p>
			<SearchBar verticalEditor={verticalEditor} isActive={true} width="70%" height="50px" />
		</div>
	) : (
		<div style={{ width: "75%" }} className="papers">
			<p style={{ textAlign: "left", marginTop: 0, paddingTop: "20px", fontSize: "12px" }}>
				{totalResults} result{totalResults > 1 && "s"} found in {totalCategories} {totalCategories === 1 ? "category" : "categories"}
			</p>
			{Object.entries(categories).map(([category, props]) => {
				return searchResults[category]?.map((result) => {
					return (
						<Card
							key={result["_id"]}
							category={category}
							details={result}
							verticalCreator={verticalEditor}
							cardHeader={props.cardHeader}
							icons={props.icons}
							urlIcons={props.urlIcons}
							dotColor={props.dotColor}
							vertical_id={vertical_id}
							query_id={query_id}
							sublist={true}
						/>
					);
				});
			})}
		</div>
	);
};
export default SearchPage;
