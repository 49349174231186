// import './DetailCard.css';
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Card from "../../components/Card/Card";
import Switch from "@mui/material/Switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useCategory } from "../../CategoryContext";

import BackButton from "../../components/BackButton";

import auth from "../../firebase";

const PatentPage = ({ category = "patent" }) => {
	const { updateCount } = useCategory();

	const user = auth.currentUser;

	const icons = {
		// 'inventors': <PeopleOutlinedIcon className='detail-icon' style={{color: 'green'}}/>,
		publication_number: <BookOutlinedIcon className="detail-icon" />,
		publication_date: <CalendarMonthOutlinedIcon className="detail-icon" />,
		assignee_original: <AccountBalanceOutlinedIcon className="detail-icon" style={{ color: "#AB6EF9" }} />
	};

	const label = { inputProps: { "aria-label": "Switch demo" } };

	const [show, setShow] = useState();
	const navigate = useNavigate();

	const [item, setItem] = useState({});
	const [verticalCreator, setVerticalCreator] = useState({});
	const [loading, setLoading] = useState(true);

	const { vertical_id, query_id, id } = useParams();

	useEffect(() => {
		const getItem = async () => {
			const item = await fetchItem();
			setItem(item.data);
			document.title = item.data.title + " - The Simple Platform";
			setShow(item.data.show);
			setVerticalCreator(item.verticalCreator);
			setLoading(false);
		};
		getItem();
	}, []);

	const fetchItem = async () => {
		const token = await user.getIdToken();
		const url = process.env.REACT_APP_FLASK_WEBSERVER + `get_${category}_details?id=${id}&vertical_id=${query_id ? query_id : vertical_id}`;
		const res = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
		const data = await res.json();
		return data;
	};

	function editContent(event) {
		event.stopPropagation();
		navigate("edit");
	}

	const hideContent = async (event) => {
		event.stopPropagation();

		const isChecked = event.target.checked;
		if (isChecked) {
			updateCount(category + "s", 1);
		} else {
			updateCount(category + "s", -1);
		}
		setShow(isChecked);

		const res = await fetch(process.env.REACT_APP_FLASK_WEBSERVER + "change_visibility?id=" + item._id + "&collection=" + category + "s", {
			method: "PATCH",
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify({ show: isChecked })
		});
		await res.json();
	};

	let userType = "u";
	if (verticalCreator) userType = "ve";

	let urlPrefix = "/" + userType + "/vertical/" + vertical_id + "/";
	if (query_id != undefined) urlPrefix = "/" + userType + "/vertical/" + vertical_id + "/query/" + query_id + "/";

	return loading ? (
		<div className="container">
			<FontAwesomeIcon icon={faSpinner} spin size="10x" color="whitesmoke"></FontAwesomeIcon>
		</div>
	) : (
		<div style={{ width: "75%", textAlign: "left" }}>
			<BackButton />
			<h4 style={{ color: "#596274" }}>PATENT</h4>

			<div className="detail-card">
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					{item?.title && <h2 className="detail-title">{item.title}</h2>}

					{verticalCreator && (
						<div style={{ display: "flex", alignItems: "center", padding: 0, margin: 0 }}>
							<FontAwesomeIcon icon={faPenToSquare} className="detail-icon clickable" onClick={(event) => editContent(event)}></FontAwesomeIcon>
							<Switch {...label} checked={show} onClick={(event) => hideContent(event)} />
						</div>
					)}
				</div>

				{item?.inventors?.length > 0 && (
					<div className="detail-item" style={{ color: "green" }}>
						<PeopleOutlinedIcon className="detail-icon-" />
						<div className="people">
							{item.inventors.map((inventor, index) => (
								<div key={index}>
									<Link to={`${urlPrefix}inventor/${inventor.name}`}>
										<span>{inventor.name}</span>
									</Link>
									{index < item.inventors.length - 1 && <span>,&nbsp;</span>}
								</div>
							))}
						</div>
					</div>
				)}

				{item?.assignees?.length > 0 && (
					<div className="detail-item">
						<AccountBalanceOutlinedIcon className="detail-icon-" style={{ color: "#AB6EF9" }} />
						<div className="people">
							{item.assignees.map((assignee, index) => (
								<span key={index}>
									{assignee}
									{index < item.assignees.length - 1 && <span>,&nbsp;</span>}
								</span>
							))}
						</div>
					</div>
				)}

				{item?.publication_number && (
					<div className="detail-item">
						<BookOutlinedIcon className="detail-icon-" />
						{item.publication_number} {item?.application_number && "(" + item.application_number + ")"}
					</div>
				)}

				{(item?.priority_date || item?.filing_date || item?.grant_date || item?.publication_date) && (
					<div className="detail-item">
						<CalendarMonthOutlinedIcon className="detail-icon-" />
						{item.publication_date && <span>Published: {item.publication_date}&nbsp;&#x2022;&nbsp;</span>}
						{item.priority_date && <span>Priority: {item.priority_date}&nbsp;&#x2022;&nbsp;</span>}
						{item.filing_date && <span>Filed: {item.filing_date}&nbsp;&#x2022;&nbsp;</span>}
						{item.grant_date && <span>Granted: {item.grant_date}&nbsp;&#x2022;&nbsp;</span>}
					</div>
				)}

				{item?.country && (
					<div className="detail-item">
						<LocationOnOutlinedIcon className="detail-icon-" style={{ color: "#FF6666" }} />
						{item.country}
					</div>
				)}

				{item?.abstract && (
					<div style={{ padding: "0 10px" }}>
						<h2 className="detail-title">Abstract</h2>
						<p style={{ margin: 0, textAlign: "justify" }}>{item.abstract}</p>
						<br></br>
					</div>
				)}

				{item?.images?.length > 0 && (
					<div style={{ paddingBottom: "20px" }}>
						<h2 className="detail-title">Images</h2>
						<div style={{ display: "flex", width: "100%", overflowX: "scroll" }}>
							{item.images?.map((i, index) => (
								<img
									key={index}
									src={i}
									style={{ border: "2px solid black", maxWidth: "100%", maxHeight: "100%", height: "250px", marginRight: "10px", display: "inline-block", verticalAlign: "middle" }}
									width="contains"
									alt="patent"
								/>
							))}
						</div>
					</div>
				)}

				{item?.pdf && (
					<div className="detail-item">
						<PictureAsPdfOutlinedIcon className="detail-icon-" />
						<a href={item.pdf.startsWith("http://") || item.pdf.startsWith("https://") ? item.pdf : `https://${item.pdf}`} target="_blank">
							{item.title + ".pdf"}
						</a>
					</div>
				)}

				{item?.classifications && (
					<div>
						<p>
							<strong>Classifications</strong>
						</p>
						<table>
							<tbody>
								{item.classifications.map((i, index) => (
									<tr key={index} style={{ paddingLeft: "15px", fontSize: "13px" }}>
										<td style={{ paddingTop: "5px", paddingBottom: "5px" }}>{i?.code}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
										<td style={{ paddingTop: "5px", paddingBottom: "5px" }}>{i?.description}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)}
			</div>

			{item?.cited_by?.original?.length > 0 && (
				<>
					<h4>Citations</h4>
					<div>
						{item.cited_by.original.map((citation, index) => (
							<Card key={index} category={"patents"} details={citation} verticalCreator={false} cardHeader={"PATENT"} icons={icons} dotColor={"orange"} vertical_id={item.vertical_id} depth={1} />
						))}
					</div>
				</>
			)}

			{item?.similar_documents?.length > 0 && (
				<>
					<h4>Similar Documents</h4>
					<div>
						{item.similar_documents.map(
							(citation, index) =>
								citation?.is_patent && <Card key={index} category={"patents"} details={citation} verticalCreator={false} cardHeader={"PATENT"} icons={icons} dotColor={"orange"} vertical_id={item.vertical_id} depth={1} />
						)}
					</div>
				</>
			)}

			{item?.patent_citations?.original?.length > 0 && (
				<>
					<h4>References</h4>
					<div>
						{item.patent_citations.original.map((citation, index) => (
							<Card key={index} category={"patents"} details={citation} verticalCreator={false} cardHeader={"PATENT"} icons={icons} dotColor={"orange"} vertical_id={item.vertical_id} depth={1} />
						))}
					</div>
				</>
			)}
		</div>
	);
};
export default PatentPage;
