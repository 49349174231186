export const validateEmail = (email) => {
	const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	return emailRegex.test(email);
};

export const validateUrl = "(http:\\/\\/)?(https:\\/\\/)?(www\\.)?[a-zA-Z0-9]+\\.[a-z]{2,4}[^ \n]*";

export const invalidUrlError = "Enter a valid URL. Examples: https://website.com, www.website.com, website.com";

export function capitalizeFirstLetter(string) {
	if (!string) return string;
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function lowercaseFirstLetter(string) {
	if (!string) return string;
	return string.charAt(0).toLowerCase() + string.slice(1);
}
