import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import NumbersOutlinedIcon from "@mui/icons-material/NumbersOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import Card from "../../components/Card/Card";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import auth from "../../firebase";
import { useCategory } from "../../CategoryContext";

import BackButton from "../../components/BackButton";

const InventorPage = ({ category = "inventor" }) => {
	const user = auth.currentUser;
	const { updateCount } = useCategory();

	const theme = useTheme();
	const activeButtonStyle = {
		fontSize: "16px",
		margin: "10px",
		width: "100%",
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
			boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
		}
	};

	const label = { inputProps: { "aria-label": "Switch demo" } };

	const [item, setItem] = useState({});
	const [verticalCreator, setVerticalCreator] = useState({});
	const [show, setShow] = useState();
	const [loading, setLoading] = useState(true);

	const { vertical_id, query_id, id } = useParams();
	const navigate = useNavigate();

	const icons = {
		inventors: <PeopleOutlinedIcon className="detail-icon" style={{ color: "green" }} />,
		inventor: <PeopleOutlinedIcon className="detail-icon" style={{ color: "green" }} />,
		publication_number: <BookOutlinedIcon className="detail-icon" />,
		publication_date: <CalendarMonthOutlinedIcon className="detail-icon" />
	};

	const urlIcons = {
		resume: <img src={`${process.env.PUBLIC_URL}/peopleIcons/cv.svg`} alt="resume" className="detail-icon clickable" />,
		LinkedIn: <img src={`${process.env.PUBLIC_URL}/peopleIcons/linkedin.svg`} alt="LinkedIn logo" className="detail-icon clickable" />,
		ResearchGate: <img src={`${process.env.PUBLIC_URL}/peopleIcons/ResearchGate.svg`} alt="ResearchGate logo" className="detail-icon clickable" />,
		GoogleScholar: <img src={`${process.env.PUBLIC_URL}/peopleIcons/googleScholar.svg`} alt="GoogleScholar logo" className="detail-icon clickable" />,
		ORCID: <img src={`${process.env.PUBLIC_URL}/peopleIcons/orcid.svg`} alt="ORCID logo" className="detail-icon clickable" />,
		X: <img src={`${process.env.PUBLIC_URL}/peopleIcons/X.svg`} alt="X logo" className="detail-icon clickable" />,
		organization_url: <img src={`${process.env.PUBLIC_URL}/peopleIcons/website.svg`} alt="X logo" className="detail-icon clickable" />
	};

	useEffect(() => {
		const getItem = async () => {
			const item = await fetchItem();
			setItem(item.data);
			document.title = item.data.name + " - The Simple Platform";
			setShow(item.data.show);
			setVerticalCreator(item.verticalCreator);
			setLoading(false);
		};
		getItem();
	}, []);

	const fetchItem = async () => {
		const token = await user.getIdToken();
		const url = process.env.REACT_APP_FLASK_WEBSERVER + `get_${category}_details?id=${id}&vertical_id=${query_id ? query_id : vertical_id}`;
		const res = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
		const data = await res.json();
		return data;
	};

	function editContent(event) {
		event.stopPropagation();
		navigate("edit");
	}

	const hideContent = async (event) => {
		event.stopPropagation();

		const isChecked = event.target.checked;
		if (isChecked) {
			updateCount("people", 1);
		} else {
			updateCount("people", -1);
		}
		setShow(isChecked);

		const res = await fetch(process.env.REACT_APP_FLASK_WEBSERVER + "change_visibility?id=" + item._id + "&collection=" + category + "s", {
			method: "PATCH",
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify({ show: isChecked })
		});
		await res.json();
	};

	return loading ? (
		<div className="container">
			<FontAwesomeIcon icon={faSpinner} spin size="10x" color="whitesmoke"></FontAwesomeIcon>
		</div>
	) : (
		<div style={{ width: "75%", textAlign: "left" }}>
			<BackButton />
			<h4 style={{ color: "#596274" }}>INVENTOR</h4>

			<div className="detail-card">
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div style={{ display: "flex", alignItems: "center" }}>
						{item?.image_url ? (
							<img src={item.image_url} alt="Profile" style={{ height: "128px", width: "128px", marginRight: "20px", marginBottom: "20px", objectFit: "cover", borderRadius: "50%" }} />
						) : (
							<AccountCircleOutlinedIcon style={{ height: "128px", width: "128px", paddingRight: "20px" }} />
						)}
						{item?.name && <h2 className="title">{item.name}</h2>}
					</div>

					{verticalCreator && (
						<div style={{ display: "flex", alignItems: "center", padding: 0, margin: 0 }}>
							<FontAwesomeIcon icon={faPenToSquare} className="detail-icon clickable" onClick={(event) => editContent(event)}></FontAwesomeIcon>
							<Switch {...label} checked={show} onClick={(event) => hideContent(event)} />
						</div>
					)}
				</div>

				{item?.affiliations && (
					<div className="detail-item-">
						<AccountBalanceOutlinedIcon className="detail-icon-" style={{ color: "#AB6EF9" }} />
						{item.affiliations}
					</div>
				)}

				{item?.department && (
					<div className="detail-item-">
						<AccountTreeOutlinedIcon className="detail-icon-" />
						{item.department}
					</div>
				)}

				{item?.program && (
					<div className="detail-item-">
						<AccountTreeOutlinedIcon className="detail-icon-" />
						{item.program}
					</div>
				)}

				{item?.email && (
					<div className="detail-item-">
						<AlternateEmailOutlinedIcon className="detail-icon-" style={{ color: "#4C90F6" }} />
						{item.email}
					</div>
				)}

				{item.organic_results && item.organic_results.length > 0 && (
					<div className="detail-item-">
						<NumbersOutlinedIcon className="detail-icon-" style={{ color: "green" }} />
						Patents: {item.patentCount}
					</div>
				)}

				<div style={{ display: "flex", flexDirection: "row" }}>
					{
						/* Rendering url icons */
						Object.keys(urlIcons).map(
							(key) =>
								item?.[key] && (
									<span className="detail-item" key={key}>
										<a target="_blank" href={item[key].startsWith("http://") || item[key].startsWith("https://") ? item[key] : `https://${item[key]}`}>
											{urlIcons[key]}
										</a>
									</span>
								)
						)
					}
				</div>

				{item?.homepage && (
					<Button
						variant="outlined"
						disableRipple={true}
						sx={activeButtonStyle}
						onClick={() => {
							const url = item.homepage;
							const prefixedUrl = url.startsWith("http://") || url.startsWith("https://") ? url : `https://${url}`;
							window.open(prefixedUrl, "_blank");
						}}>
						Visit Homepage
					</Button>
				)}

				{item?.about && (
					<div style={{ padding: "0 10px" }}>
						<h2 className="detail-title">About</h2>
						<p style={{ margin: 0, textAlign: "justify", whiteSpace: "pre-line" }}>{item.about}</p>
					</div>
				)}
			</div>

			{item.source_patents && item.source_patents.length > 0 && (
				<>
					<h4>Vertical Specific Patents</h4>
					<div>
						{item.source_patents.map((patent, index) => (
							<Card key={index} category={"patents"} details={patent} verticalCreator={verticalCreator} cardHeader={"PATENT"} icons={icons} dotColor={"orange"} vertical_id={vertical_id} sublist={true} query_id={query_id} />
						))}
					</div>
				</>
			)}

			{item.organic_results && item.organic_results.length > 0 && (
				<>
					<h4>All Patents</h4>
					<div>
						{item.organic_results.map((patent, index) => (
							<Card key={index} category={"patents"} details={patent} verticalCreator={verticalCreator} cardHeader={"PATENT"} icons={icons} dotColor={"orange"} vertical_id={vertical_id} depth={1} />
						))}
					</div>
				</>
			)}
		</div>
	);
};
export default InventorPage;
