import { useNavigate, useLocation } from "react-router-dom";
import auth from "../../firebase";
import styles from "./Auth.module.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SubmitButton from "./SubmitButton";
import { useState } from "react";
import { innovator, organization, finance, service } from "./roles";
const SelectRolePage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { role, verticalJoined } = location.state || {};
	const [submitting, setSubmitting] = useState(false);
	const user = auth.currentUser;

	const onSubmit = async (e) => {
		e.preventDefault();
		setSubmitting(true);
		const formData = new FormData(e.target);
		const selectedSubRole = formData.get("subRole");
		console.log("url: ", "/u/vertical/" + verticalJoined + "/home");
		const url = process.env.REACT_APP_FLASK_WEBSERVER + "edit_user?uid=" + user.uid;
		await fetch(url, {
			method: "PATCH",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({ subRole: selectedSubRole })
		});
		setSubmitting(false);

		navigate("/u/vertical/" + verticalJoined + "/home");
	};

	let subRole;
	if (role === "Innovator and Researcher") {
		subRole = innovator;
	} else if (role === "Finance & Investment") {
		subRole = finance;
	} else if (role === "Organization") {
		subRole = organization;
	} else if (role === "Service Providers & Consultants") {
		subRole = service;
	}

	return (
		<div>
			<h2>
				Your role is <span style={{ color: "#356AC3" }}>{role}</span>
			</h2>
			<p style={{ fontWeight: "500", fontSize: "20px", marginBottom: 0 }}>сhoose the directions that suit you best</p>
			<p className={styles.roleDescription} style={{ textAlign: "center" }}>
				* You can add the role in your account anytime later
			</p>
			<form onSubmit={onSubmit} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", minWidth: "50%", margin: "0 auto" }}>
				<div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
					<span style={{ fontWeight: "700" }}>Skip*&nbsp;&nbsp;</span>
					<ArrowForwardIcon className={styles.skipButton} fontSize="small" onClick={() => navigate("/u/vertical/" + verticalJoined + "/home")} />
				</div>
				{subRole.map((subRole, index) => (
					<div className={`${styles.role} ${styles.subRole}`}>
						<input type="radio" id={subRole} name="subRole" value={subRole} defaultChecked={index === 0}></input>
						<label for={subRole}>{subRole}</label>
					</div>
				))}
				<div className={styles.footer}>
					<SubmitButton text="Continue" width="25%" height="48px" disabled={submitting} />
				</div>
			</form>
		</div>
	);
};
export default SelectRolePage;
