const Stepper = ({ label1, label2, label3, line1b, circle2, line2a, line2b, circle3 }) => {
	const activeColor = "linear-gradient(90deg, #202B86 0%, #206286 91.67%)";
	const inactiveColor = "#98A2B3";

	return (
		<div style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
			<div style={{ display: "flex", alignItems: "center", paddingLeft: "20px", paddingRight: "20px" }}>
				{/* Circle 1 */}
				<div style={{ width: "8px", height: "8px", borderRadius: "50%", background: activeColor }}></div>
				{/* Line 1a*/}
				<div style={{ width: "5vw", marginRight: "1px", height: "2px", background: activeColor }}></div>
				{/* Line 1b*/}
				<div style={{ width: "5vw", height: "2px", background: line1b ? activeColor : inactiveColor }}></div>
				{/* Circle 2 */}
				<div style={{ width: "8px", height: "8px", borderRadius: "50%", background: circle2 ? activeColor : inactiveColor }}></div>
				{/* Line 2a*/}
				<div style={{ width: "5vw", marginRight: "1px", height: "2px", background: line2a ? activeColor : inactiveColor }}></div>
				{/* Line 2b*/}
				<div style={{ width: "5vw", height: "2px", background: line2b ? activeColor : inactiveColor }}></div>
				{/* Circle 3 */}
				<div style={{ width: "8px", height: "8px", borderRadius: "50%", background: circle3 ? activeColor : inactiveColor }}></div>
			</div>
			<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "6px" }}>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<p style={{ padding: 0, margin: 0, fontSize: "12px", fontWeight: 600, lineHeight: "16px" }}>{label1}</p>
					<p style={{ padding: 0, margin: 0, fontSize: "11px", fontWeight: 400, color: "#5F6A7D", lineHeight: "16px" }}>Step 1</p>
				</div>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<p style={{ padding: 0, margin: 0, fontSize: "12px", fontWeight: 600, lineHeight: "16px" }}>{label2}</p>
					<p style={{ padding: 0, margin: 0, fontSize: "11px", fontWeight: 400, color: "#5F6A7D", lineHeight: "16px" }}>Step 2</p>
				</div>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<p style={{ padding: 0, margin: 0, fontSize: "12px", fontWeight: 600, lineHeight: "16px" }}>{label3}</p>
					<p style={{ padding: 0, margin: 0, fontSize: "11px", fontWeight: 400, color: "#5F6A7D", lineHeight: "16px" }}>Step 3</p>
				</div>
			</div>
		</div>
	);
};
export default Stepper;
