import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Badge from "@mui/material/Badge";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import "./Sidebar.css";
import { useCategory } from "../../CategoryContext";

const Sidebar = ({ verticalEditor }) => {
	const { categories } = useCategory();
	const { vertical_id, query_id } = useParams();
	const location = useLocation();

	const menuItems = [
		{ icon: <HomeOutlinedIcon />, text: "Home" },
		{ icon: <img src={`${process.env.PUBLIC_URL}/categoryIcons/publications.svg`} />, text: "Publications", path: "paper", count: "papers" },
		{ icon: <img src={`${process.env.PUBLIC_URL}/categoryIcons/patents.svg`} />, text: "Patents", count: "patents" },
		{ icon: <img src={`${process.env.PUBLIC_URL}/categoryIcons/funding.svg`} />, text: "Funding", path: "grant", count: "grants" },
		{ icon: <img src={`${process.env.PUBLIC_URL}/categoryIcons/people.svg`} />, text: "People", path: "author", path2: "inventor", count: "people" },
		{ icon: <img src={`${process.env.PUBLIC_URL}/categoryIcons/jobs.svg`} />, text: "Jobs", count: "jobs" },
		{ icon: <img src={`${process.env.PUBLIC_URL}/categoryIcons/organizations.svg`} />, text: "Organizations", count: "organizations" },
		{ icon: <img src={`${process.env.PUBLIC_URL}/categoryIcons/events.svg`} />, text: "Events", count: "events" },
		{ icon: <img src={`${process.env.PUBLIC_URL}/categoryIcons/tutorials.svg`} />, text: "Tutorials", count: "tutorials" }
	];

	let userType;
	if (verticalEditor) {
		userType = "ve";
	} else {
		userType = "u";
	}

	return (
		<div className="sidebar">
			<div>
				{menuItems.map((item, index) => (
					<Badge
						anchorOrigin={{
							vertical: "top",
							horizontal: "left"
						}}
						badgeContent={categories[item.count]}
						max={9999}
						sx={{ display: "block" }}
						showZero
						slotProps={{
							badge: {
								sx: { backgroundColor: "black", color: "white", top: "10px" } // Apply styles specifically to badge content
							}
						}}>
						<NavLink
							key={index}
							to={query_id == undefined ? "/" + userType + `/vertical/${vertical_id}/${item.text.toLowerCase()}` : "/" + userType + `/vertical/${vertical_id}/query/${query_id}/${item.text.toLowerCase()}`}
							className={({ isActive }) => (isActive || location.pathname.includes(item.path) || location.pathname.includes(item.path2) ? "sidebar-item-active" : "sidebar-item")}
							style={{ display: "flex", alignItems: "center" }}>
							{item.icon}
							<span>{item.text}</span>
						</NavLink>
					</Badge>
				))}
			</div>
			<div className="sidebarfooter">
				<Link to={"https://www.simple-platform.com/"} target="_blank">
					<img src={`${process.env.PUBLIC_URL + "/logo.svg"}`} alt="TSP logo" />
				</Link>
			</div>
		</div>
	);
};

export default Sidebar;
