import Button from "@mui/material/Button";
import auth from "../../firebase";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { roles, innovator, finance, organization, service } from "./roles";
import styles from "./Auth.module.css";
import SubmitButton from "./SubmitButton";
import DropDownMenu from "./DropDownMenu";

const ProfilePage = () => {
	const [user, setUser] = useState("");
	const [firstName, setFirstName] = useState("");
	const [middleName, setMiddleName] = useState("");
	const [lastName, setLastName] = useState("");
	const [role, setRole] = useState("");
	const [subRole, setSubRole] = useState("");
	const [submitting, setSubmitting] = useState(false);
	let subRoles = [];

	const handleButtonClick = (url) => {
		window.open(url, "_blank");
	};

	switch (role) {
		case "Innovator and Researcher":
			subRoles = innovator;
			break;
		case "Finance & Investment":
			subRoles = finance;
			break;
		case "Organization":
			subRoles = organization;
			break;
		case "Service Providers & Consultants":
			subRoles = service;
			break;
	}

	function splitName(fullName) {
		const nameParts = fullName.split(" ");
		const firstName = nameParts[0];
		const lastName = nameParts[nameParts.length - 1];
		const middleName = nameParts.slice(1, -1).join(" ");

		return {
			firstName,
			middleName,
			lastName
		};
	}

	useEffect(() => {
		document.title = "Profile - The Simple Platform";
		const getUser = async () => {
			const user = auth.currentUser;
			if (user) {
				setUser(user);

				const { firstName, middleName, lastName } = splitName(user.displayName || "");
				setFirstName(firstName);
				setMiddleName(middleName);
				setLastName(lastName);

				const userFromDb = await fetchUser(user.uid);

				if (userFromDb.role) {
					setRole(userFromDb.role);
				}

				if (userFromDb.subRole) {
					setSubRole(userFromDb.subRole);
				}
			}
		};
		getUser();
	}, []);

	const fetchUser = async (uid) => {
		const url = process.env.REACT_APP_FLASK_WEBSERVER + "get_user?uid=" + uid;
		const res = await fetch(url);
		const user = await res.json();
		return user;
	};

	const submitForm = async (e) => {
		setSubmitting(true);
		e.preventDefault();

		const url = process.env.REACT_APP_FLASK_WEBSERVER + "edit_user?uid=" + user.uid;
		const newName = firstName + " " + middleName + " " + lastName;

		let data = {};
		if (user.displayName !== newName) {
			data["display_name"] = newName;
		}
		data["role"] = role;
		data["subRole"] = subRole;

		if (Object.keys(data).length !== 0) {
			const res = await fetch(url, {
				method: "PATCH",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(data)
			});

			if (res.status === 200) {
				toast.success("Profile updated successfully!", {
					className: "toast",
					autoClose: 3000
				});
			} else {
				toast.error("Unable to update profile", {
					className: "toast",
					autoClose: 3000
				});
			}
		}
		setSubmitting(false);
	};

	const changeRole = (role) => {
		setRole(role);
		setSubRole("");
	};

	const changeSubRole = (role) => {
		setSubRole(role);
	};

	return (
		<div style={{ width: "75vw" }}>
			<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<h2>My Profile</h2>
				<div>
					<Button
						variant="outlined"
						sx={{ textTransform: "none", margin: "0 10px" }}
						onClick={() => {
							handleButtonClick("https://www.simple-platform.com/terms-of-use");
						}}>
						Terms of Use
					</Button>
					<Button
						variant="outlined"
						sx={{ textTransform: "none" }}
						onClick={() => {
							handleButtonClick("https://www.simple-platform.com/privacy-policy");
						}}>
						Privacy Policy
					</Button>
				</div>
			</div>

			<form onSubmit={submitForm} style={{ display: "flex", flexDirection: "column", gap: "10px", textAlign: "left", width: "400px" }}>
				<div>
					<label className={styles["input-label"]}>First Name</label>
					<input className={styles["input-text"]} type="text" id="firstname" name="firstname" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
				</div>
				<div>
					<label className={styles["input-label"]}>Middle Name</label>
					<input className={styles["input-text"]} type="text" id="middlename" name="middlename" value={middleName} onChange={(e) => setMiddleName(e.target.value)} />
				</div>
				<div>
					<label className={styles["input-label"]}>Last Name</label>
					<input className={styles["input-text"]} type="text" id="lastname" name="lastname" value={lastName} onChange={(e) => setLastName(e.target.value)} />
				</div>
				<div>
					<label className={styles["input-label"]}>Email</label>
					<input className={styles["input-text"]} type="text" id="email" name="email" disabled value={user.email} />
				</div>

				<DropDownMenu label="Role" activeItem={role} items={roles} changeSelection={changeRole} placeholder="Select your role" />
				<DropDownMenu label="Sub Role" activeItem={subRole} items={subRoles} changeSelection={changeSubRole} placeholder="Select your sub role" />

				<div style={{ paddingTop: "20px" }}>
					<SubmitButton text="Save Changes" disabled={submitting} width="100%" height="40px" fontSize="14px" fontWeight="400" />
				</div>
			</form>
		</div>
	);
};
export default ProfilePage;
