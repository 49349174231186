import { toast } from "react-toastify";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { capitalizeFirstLetter, lowercaseFirstLetter } from "../../utils/functions";
import "./toast.css";

export const SuccessToast = ({ category, link = false, title = "", _id = "", functionality = "add" }) => {
	toast.success(
		<>
			<span>
				<div style={{ display: "flex", alignItems: "center", gap: "20px", paddingBottom: "10px" }}>
					<CheckCircleIcon style={{ color: "#09b899" }} />
					<span style={{ color: "black" }}>{`${capitalizeFirstLetter(category)} ${functionality}ed successfully!\n`}</span>
				</div>
				{link && (
					<a
						href={category !== "person" ? lowercaseFirstLetter(category) + "s/" + _id : "people/" + _id}
						target="_blank"
						style={{ color: "#356AC3", textDecoration: "none", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
						<span>{title.length > 22 ? title.substring(0, 22) + "..." : title}</span>
						<OpenInNewIcon />
					</a>
				)}
			</span>
		</>,
		{
			className: "toast",
			autoClose: 8000,
			icon: false
		}
	);
};

export const ErrorToast = ({ category, response = 0, functionality = "add" }) => {
	console.log("response: ", response);
	const message = response === 400 ? `${category} already added!` : `Unable to ${functionality} ${category}!`;
	toast.error(message, {
		className: "toast",
		autoClose: 8000
	});
};
