import { useState, useEffect } from "react";
import Card from "../../components/Card/Card";
import auth from "../../firebase";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { categories } from "../../utils/cardInfo";

const HomePage = () => {
	const [items, setItems] = useState();
	const [loading, setLoading] = useState(true);
	const [verticalCreator, setVerticalCreator] = useState();
	// const [tutorialCreator, setTutorialCreator] = useState(false);

	const user = auth.currentUser;
	const { vertical_id, query_id } = useParams();

	useEffect(() => {
		document.title = "The Simple Platform";
	}, []);

	useEffect(() => {
		document.title = "Home - The Simple Platform";
		const getItems = async () => {
			const items = await fetchItems();
			setItems(items);
			setVerticalCreator(items.verticalCreator);
			setLoading(false);
		};
		getItems();
	}, []);

	const fetchItems = async () => {
		const token = await user.getIdToken();

		let id;
		if (query_id) {
			id = query_id;
		} else {
			id = vertical_id;
		}

		const url = process.env.REACT_APP_FLASK_WEBSERVER + "get_home_page" + "?vertical_id=" + id;
		const res = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
		const data = await res.json();
		return data;
	};

	return loading ? (
		<div className="container">
			<FontAwesomeIcon icon={faSpinner} spin size="10x" color="whitesmoke"></FontAwesomeIcon>
		</div>
	) : (
		<div style={{ width: "75%" }} className="papers">
			<h3 style={{ marginBottom: 0 }}>Welcome back, {user.displayName} &#128075;! </h3>
			<h4 style={{ marginBottom: 0 }}>Latest Updates:</h4>
			{Object.entries(categories).map(([category, props]) => {
				return (
					items[category] && (
						<Card
							key={items[category]["_id"]}
							category={category}
							details={items[category]}
							verticalCreator={verticalCreator}
							cardHeader={props?.cardHeader}
							icons={props.icons}
							urlIcons={props.urlIcons}
							dotColor={props.dotColor}
							vertical_id={vertical_id}
							query_id={query_id}
							sublist={true}
						/>
					)
				);
			})}
		</div>
	);
};

export default HomePage;
