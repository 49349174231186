import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const SubmitButton = ({ text, disabled, disabledText, width = "100%", height = "48px", margin = 0, fontSize = "16px", fontWeight = 700, onClick }) => {
	const theme = useTheme();

	const buttonStyle = {
		textTransform: "none",
		backgroundColor: "#356AC3",
		color: theme.palette.primary.contrastText,
		width: width,
		height: height,
		padding: "12px 0",
		fontSize: fontSize,
		fontWeight: fontWeight,
		margin: margin,
		border: "none",
		transition: "none",
		"&:hover": {
			backgroundColor: "#356AC3",
			borderColor: "#356AC3"
		},
		"&:disabled": {
			backgroundColor: "#7B828A",
			borderColor: "#7B828A",
			color: theme.palette.primary.contrastText
		}
	};
	return (
		<Button type="submit" variant="outlined" disableRipple={true} sx={buttonStyle} disabled={disabled} onClick={onClick}>
			{!disabled ? text : disabledText ? text : <FontAwesomeIcon icon={faSpinner} spin size="2x"></FontAwesomeIcon>}
		</Button>
	);
};
export default SubmitButton;
