import { signInWithEmailAndPassword, setPersistence, browserSessionPersistence, browserLocalPersistence } from "firebase/auth";
import auth from "../../firebase";
import { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Button from "@mui/material/Button";
import styles from "./Auth.module.css";
import Password from "./Password";
import SubmitButton from "./SubmitButton";

const LogInPage = ({}) => {
	let location = useLocation();
	let searchParams = new URLSearchParams(location.search);
	let emailId = searchParams.get("emailId");

	const [email, setEmail] = useState(emailId);
	const [password, setPassword] = useState("");
	const [rememberMe, setRememberMe] = useState(true);
	const [correctEmail, setCorrectEmail] = useState(true);
	const [validEmail, setValildEmail] = useState(true);
	const [correctPwd, setCorrectPwd] = useState(true);
	const [submitting, setSubmitting] = useState(false);

	const navigate = useNavigate();

	const onSubmit = async (e) => {
		e.preventDefault();
		setSubmitting(true);
		await setPersistence(auth, rememberMe ? browserLocalPersistence : browserSessionPersistence);

		await signInWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				// Signed in
				const user = userCredential.user;
				user.getIdTokenResult().then((idTokenResult) => {
					if (idTokenResult.claims.isVerticalEditor === true) {
						navigate("/ve");
					} else {
						navigate("/u/vertical/" + idTokenResult.claims.verticalJoined + "/home");
					}
				});
			})
			.catch((error) => {
				setSubmitting(false);

				const errorCode = error.code;
				const errorMessage = error.message;

				if (errorCode === "auth/user-not-found") {
					setCorrectEmail(false);
					setCorrectPwd(true);
				} else if (errorCode === "auth/wrong-password") {
					setCorrectPwd(false);
					setCorrectEmail(true);
				}
			});
	};

	const forgotPassword = async (e) => {
		e.preventDefault();
		// setEmail_(email);
		navigate("/reset-password");
	};

	const onChange = (e) => {
		setPassword(e.target.value);
	};

	const validateEmail = (e) => {
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		setValildEmail(e.target.value.length > 0 ? emailRegex.test(e.target.value) : true);
	};

	return (
		<div style={{ width: "320px" }}>
			<h2>Login</h2>
			<form onSubmit={onSubmit} autoComplete="off">
				<p className={styles["input-label"]}>Your email</p>
				<div>
					<input
						className={`${styles["input-email"]} ${!correctEmail || !validEmail ? styles["input-error"] : ""}`}
						type="email"
						placeholder="Enter your work email"
						autoComplete="off"
						required
						value={email}
						onChange={(e) => {
							setEmail(e.target.value);
							const emailCheck = /@.*\.[a-z]{2}/;
							if (emailCheck.test(e.target.value)) {
								validateEmail(e);
							}
						}}
						onBlur={validateEmail}
					/>
				</div>
				{!validEmail && <p className={styles.error}>Make sure that email address is correct.</p>}
				{!correctEmail && <p className={styles.error}>User is not found. If you don’t have an account join the waitlist and we review your request shortly.</p>}

				<Password password={password} onChange={onChange} error={!correctPwd} placeholder="Enter your password" />

				{!correctPwd && <p className={styles.error}>Something went wrong. Check password & try again.</p>}

				<div style={{ display: "flex", justifyContent: "space-between", textAlign: "left", fontSize: "12px", paddingTop: "10px" }}>
					<div>
						<input checked={rememberMe} type="checkbox" className={styles["checkbox"]} onChange={() => setRememberMe((prevValue) => !prevValue)} />
						<label className={styles["checkbox-label"]}>Remember me</label>
					</div>
					<Link to="#" onClick={forgotPassword} className={styles.forgotPassword}>
						FORGOT PASSWORD
					</Link>
				</div>

				<div style={{ paddingTop: "20px" }}>
					<SubmitButton text={"Continue"} disabled={submitting || !validEmail || password?.length < 5} disabledText={!validEmail || password?.length < 5} />
				</div>
			</form>

			<p style={{ fontSize: "16px", whiteSpace: "nowrap", marginTop: "60px" }}>Want to access our invitation-only platform?</p>
			<Button variant="outlined" sx={{ textTransform: "none", width: "100%", height: "50px", fontWeight: 700 }} onClick={() => window.open("https://forms.gle/Xe32zwcci4GaDcJB8", "_blank")}>
				Join Waitlist
			</Button>
		</div>
	);
};

export default LogInPage;
