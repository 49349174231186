import { useState, useEffect, useRef } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import styles from "./Auth.module.css";

const DropDownMenu = ({ label, activeItem, items, changeSelection, placeholder }) => {
	const [menuOpen, setMenuOpen] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		document.addEventListener("mousedown", closeDropDown);

		return () => {
			document.removeEventListener("mousedown", closeDropDown);
		};
	}, []);

	const closeDropDown = (event) => {
		if (ref.current && !ref.current.contains(event.target)) {
			setMenuOpen(false);
		}
	};

	return (
		<div>
			<label className={styles["input-label"]}>{label}</label>

			<div className={styles.customDropDown} ref={ref} onClick={() => setMenuOpen((prevValue) => !prevValue)}>
				{activeItem === "" ? <p style={{ color: "lightgray", marginLeft: "10px" }}>{placeholder}</p> : <p style={{ paddingLeft: "10px" }}>{activeItem}</p>}
				<ArrowDropDownIcon style={{ padding: "10px" }} />

				<div style={{ display: menuOpen ? "block" : "none" }} className={styles.menuContainer}>
					{!Array.isArray(items) ? (
						Object.entries(items).map(([item, description]) => (
							<div onClick={() => changeSelection(item)} className={item === activeItem ? styles.menuItemActive : styles.menuItem}>
								<div>
									<label for={item}>{item}</label>
									<p className={styles.roleDescription} style={{ marginBottom: 0 }}>
										{description}
									</p>
								</div>
							</div>
						))
					) : (
						<div>
							{items.map((item) => (
								<div onClick={() => changeSelection(item)} className={item === activeItem ? styles.menuItemActive : styles.menuItem}>
									<label for={item}>{item}</label>
								</div>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
export default DropDownMenu;
