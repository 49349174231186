import { createContext, useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// 1. Create the Context
const CategoryContext = createContext();

// 2. Create a custom hook to use the Context more easily
export const useCategory = () => useContext(CategoryContext);

const fetchCategoryCounts = async (id) => {
	const url = process.env.REACT_APP_FLASK_WEBSERVER + "get_category_counts?vertical_id=" + id;
	const res = await fetch(url, {
		method: "GET"
	});
	const data = await res.json();
	return data;
};

// 3. Create a provided component
export const CategoryProvider = ({ children }) => {
	const [categories, setCategories] = useState({});
	const { vertical_id, query_id } = useParams();
	const id = query_id ? query_id : vertical_id;
	// Initial fetch to get category counts, only once on component mount
	useEffect(() => {
		fetchCategoryCounts(id).then((initialCounts) => setCategories(initialCounts));
	}, [vertical_id, query_id]);

	const updateCount = (categoryId, change) => {
		// setCategories((prevCategories) => prevCategories.map((category) => (category.id === categoryId ? { ...category, count: newCount } : category)));
		setCategories((prevCategories) => ({
			...prevCategories, // Copy all previous keys and values
			[categoryId]: categories[categoryId] + change
		}));
	};

	const refreshCategoryCounts = (id) => {
		fetchCategoryCounts(id).then((initialCounts) => setCategories(initialCounts));
	};

	// 4. Value provided to the Context consumers
	return <CategoryContext.Provider value={{ categories, updateCount, refreshCategoryCounts }}>{children}</CategoryContext.Provider>;
};
