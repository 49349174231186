export const roles = {
	"Innovator and Researcher":
		"Researchers, academics, creators, founders and others looking for easy access to aggregated information, downloadable research at low/no cost, access to funding and other collaboration information and other information relevant to pursuing, enhancing and commercializing ideas",
	"Finance & Investment": "Angel Investor, Finance Corporate, Family Office, Incubator, Philanthropic, VC/Private Equity, Institutional Investors",
	Organization: "Academic and Research Institution, Government, NGO, Grant Giver",
	"Service Providers & Consultants": "Consultants, Equipment and Service Providers, Professional Society"
};

export const innovator = [
	"PhD Student/Candidate",
	"Postdoc Research Assistant (working under principal investigator)",
	"Adjunct Professor",
	"Postdoc Research Fellow (with own funding)",
	"Non-tenure Track Professor",
	"Tenure-track/Established Professor",
	"Senior Research Fellow",
	"Independent Academic Researcher",
	"Corporate/Industry Researcher",
	"Independent Innovator",
	"Other"
];
export const finance = ["Angel Investors", "Corporate (finance related)", "Family Offices", "Incubators", "Philanthropic Organizations", "Venture Capital/Private Equity Firms", "Other"];
export const organization = ["Academic and Research Institutions", "Government Agencies", "NGO", "Specialized Grant Program", "Other"];
export const service = ["Consultants", "Equipment and Other Service Providers", "Journalists", "Professional Societies", "Other"];
