import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SubmitButton from "./SubmitButton";

const PasswordChangedPage = () => {
	const navigate = useNavigate();
	return (
		<div style={{ width: "320px" }}>
			<CheckCircleIcon sx={{ color: "#63A033", fontSize: 70 }} />
			<h2>All done!</h2>
			<p style={{ fontSize: "16px", color: "#596274" }}>Your password has been reset.</p>
			<div style={{ paddingTop: "20px" }}>
				<SubmitButton text="Open TSP" onClick={() => navigate("/login")} />
			</div>
		</div>
	);
};
export default PasswordChangedPage;
