import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

import Button from "@mui/material/Button";

import { useTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

import auth from "../../firebase";
import { useCategory } from "../../CategoryContext";

import BackButton from "../../components/BackButton";

const DetailPage = ({ category, heading, field_icon, importantDate, externalLink, field_heading, url_icon }) => {
	const { updateCount } = useCategory();
	const [item, setItem] = useState({});
	const [verticalCreator, setVerticalCreator] = useState({});
	const [loading, setLoading] = useState(true);
	const [show, setShow] = useState();

	const { vertical_id, query_id, id } = useParams();
	const navigate = useNavigate();

	const label = { inputProps: { "aria-label": "Switch demo" } };
	const theme = useTheme();

	const user = auth.currentUser;

	const activeButtonStyle = {
		fontSize: "16px",
		margin: "10px",
		width: "100%",
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
			boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
		}
	};

	useEffect(() => {
		const getItem = async () => {
			const item = await fetchItem();
			setItem(item.data);
			document.title = item.data[heading] + " - The Simple Platform";
			setShow(item.data.show);
			setVerticalCreator(item.verticalCreator);
			setLoading(false);
		};
		getItem();
	}, []);

	const fetchItem = async () => {
		const token = await user.getIdToken();

		let url;
		if (category !== "tutorial") url = process.env.REACT_APP_FLASK_WEBSERVER + `get_${category}_details?id=${id}&vertical_id=${query_id ? query_id : vertical_id}`;
		else url = process.env.REACT_APP_FLASK_WEBSERVER + "get_" + category + "_details?id=" + id;

		const res = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
		const data = await res.json();
		return data;
	};

	function editContent(event) {
		event.stopPropagation();
		navigate("edit");
	}

	const hideContent = async (event) => {
		event.stopPropagation();

		const isChecked = event.target.checked;
		let sideBarCategory = category === "person" ? "people" : category + "s";
		if (isChecked) {
			updateCount(sideBarCategory, 1);
		} else {
			updateCount(sideBarCategory, -1);
		}
		setShow(isChecked);

		let collection;
		if (category === "person") {
			collection = "people";
		} else {
			collection = category + "s";
		}

		const res = await fetch(process.env.REACT_APP_FLASK_WEBSERVER + "change_visibility?id=" + item._id + "&collection=" + collection, {
			method: "PATCH",
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify({ show: isChecked })
		});
		await res.json();
	};

	return loading ? (
		<div className="container">
			<FontAwesomeIcon icon={faSpinner} spin size="10x" color="whitesmoke"></FontAwesomeIcon>
		</div>
	) : (
		<div style={{ width: "75%", textAlign: "left" }}>
			<BackButton />
			<h4 style={{ color: "#596274" }}>{item?.type ? item.type.toUpperCase() : category.toUpperCase()}</h4>
			<div className="detail-card">
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div style={{ display: "flex", alignItems: "center", paddingBottom: "20px" }}>
						{category === "organization" &&
							(item?.image_url ? (
								<img src={item.image_url} alt="Organization" style={{ height: "128px", width: "128px", marginRight: "20px", objectFit: "cover" }} />
							) : (
								<AccountBalanceOutlinedIcon style={{ height: "128px", width: "128px", paddingRight: "20px" }} />
							))}
						{category === "person" &&
							(item?.image_url ? (
								<img src={item.image_url} alt="Person" style={{ height: "128px", width: "128px", marginRight: "20px", objectFit: "cover", borderRadius: "50%" }} />
							) : (
								<AccountCircleOutlinedIcon style={{ height: "128px", width: "128px", paddingRight: "20px" }} />
							))}
						<h2 className="detail-title">{item[heading]}</h2>
					</div>

					{(verticalCreator || item?.creator) && (
						<div style={{ display: "flex", alignItems: "center", padding: 0, margin: 0 }}>
							<FontAwesomeIcon icon={faPenToSquare} className="detail-icon clickable" onClick={(event) => editContent(event)}></FontAwesomeIcon>
							<Switch {...label} checked={show} onClick={(event) => hideContent(event)} />
						</div>
					)}
				</div>

				{
					/* Rendering field with icons */
					Object.keys(field_icon).map(
						(key) =>
							item?.[key] && (
								<div className="detail-item" key={key}>
									{field_icon[key]}
									<span>{item[key]}</span>
								</div>
							)
					)
				}

				<div style={{ display: "flex", flexDirection: "row" }}>
					{
						/* Rendering url icons */
						url_icon &&
							Object.keys(url_icon).map(
								(key) =>
									item?.[key] && (
										<span className="detail-item" key={key}>
											<a target="_blank" href={item[key].startsWith("http://") || item[key].startsWith("https://") ? item[key] : `https://${item[key]}`}>
												{url_icon[key]}
											</a>
										</span>
									)
							)
					}
				</div>

				{importantDate && item?.[importantDate.field] && (
					<div className="detail-item" style={{ border: "1px solid #356AC3", borderRadius: "4px", color: "#356AC3", fontWeight: 700, display: "flex", width: "320px" }}>
						<EventAvailableOutlinedIcon className="detail-icon-" />
						<span>
							{importantDate.text}: {item[importantDate.field]}
						</span>
					</div>
				)}

				{externalLink && item?.[externalLink.field] && (
					<Button
						variant="outlined"
						disableRipple={true}
						sx={activeButtonStyle}
						onClick={() => {
							const url = item[externalLink.field];
							const prefixedUrl = url.startsWith("http://") || url.startsWith("https://") ? url : `https://${url}`;
							window.open(prefixedUrl, "_blank");
						}}>
						{externalLink.text}
					</Button>
				)}

				{
					/* Rendering field with headings */
					Object.keys(field_heading).map(
						(key) =>
							item?.[key] && (
								<div style={{ padding: "0 10px" }} key={key}>
									{/* {field_icon[key]}
										<span>{item[key]}</span> */}

									<h2 className="detail-title">{field_heading[key]}</h2>
									<p style={{ margin: 0, textAlign: "justify", whiteSpace: "pre-line" }}>{item[key]}</p>
								</div>
							)
					)
				}
			</div>
		</div>
	);
};
export default DetailPage;
