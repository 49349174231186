import "./DetailCard.css";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import FormatQuoteOutlinedIcon from "@mui/icons-material/FormatQuoteOutlined";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Card from "../Card/Card";
import Switch from "@mui/material/Switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useCategory } from "../../CategoryContext";
import auth from "../../firebase";

const DetailCard = ({ category }) => {
	const { updateCount } = useCategory();
	const user = auth.currentUser;
	const label = { inputProps: { "aria-label": "Switch demo" } };

	const [show, setShow] = useState();
	const navigate = useNavigate();

	const icons = {
		authors: <PeopleOutlinedIcon className="detail-icon" style={{ color: "green" }} />,
		venue: <BookOutlinedIcon className="detail-icon" />,
		publicationDate: <CalendarMonthOutlinedIcon className="detail-icon" />
	};

	const [item, setItem] = useState({});
	const [verticalCreator, setVerticalCreator] = useState({});
	const [loading, setLoading] = useState(true);

	const { vertical_id, query_id, id } = useParams();

	useEffect(() => {
		const getItem = async () => {
			const item = await fetchItem();
			setItem(item.data);
			document.title = item.data.title + " - The Simple Platform";
			setShow(item.data.show);
			setVerticalCreator(item.verticalCreator);
			setLoading(false);
		};
		getItem();
	}, []);

	const fetchItem = async () => {
		const token = await user.getIdToken();
		const url = process.env.REACT_APP_FLASK_WEBSERVER + `get_${category}_details?id=${id}&vertical_id=${query_id ? query_id : vertical_id}`;
		const res = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
		const data = await res.json();
		return data;
	};

	function editContent(event) {
		event.stopPropagation();
		navigate("edit");
	}

	const hideContent = async (event) => {
		event.stopPropagation();

		const isChecked = event.target.checked;
		if (isChecked) {
			updateCount(category + "s", 1);
		} else {
			updateCount(category + "s", -1);
		}
		setShow(isChecked);

		const res = await fetch(process.env.REACT_APP_FLASK_WEBSERVER + "change_visibility?id=" + item._id + "&collection=" + category + "s", {
			method: "PATCH",
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify({ show: isChecked })
		});
		await res.json();
	};

	let userType = "u";
	if (verticalCreator) userType = "ve";

	let urlPrefix = "/" + userType + "/vertical/" + vertical_id + "/";
	if (query_id != undefined) urlPrefix = "/" + userType + "/vertical/" + vertical_id + "/query/" + query_id + "/";

	return loading ? (
		<div className="container">
			<FontAwesomeIcon icon={faSpinner} spin size="10x" color="whitesmoke"></FontAwesomeIcon>
		</div>
	) : (
		<>
			<div className="detail-card">
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					{item?.title && <h2 className="detail-title">{item.title}</h2>}

					{verticalCreator && (
						<div style={{ display: "flex", alignItems: "center", padding: 0, margin: 0 }}>
							<FontAwesomeIcon icon={faPenToSquare} className="detail-icon clickable" onClick={(event) => editContent(event)}></FontAwesomeIcon>
							<Switch {...label} checked={show} onClick={(event) => hideContent(event)} />
						</div>
					)}
				</div>

				{item?.authors?.length > 0 && (
					<div className="detail-item" style={{ color: "green" }}>
						<PeopleOutlinedIcon className="detail-icon-" />
						<div className="people">
							{item?.authors?.map((author, index) => (
								<div key={author.authorId}>
									<Link to={`${urlPrefix}author/${author.authorId}`}>
										<span>{author.name}</span>
									</Link>
									{index < item.authors.length - 1 && <span>,&nbsp;</span>}
								</div>
							))}
						</div>
					</div>
				)}

				{item?.venue && (
					<div className="detail-item">
						<BookOutlinedIcon className="detail-icon-" />
						{item.venue}
					</div>
				)}

				{item?.publicationDate && (
					<div className="detail-item">
						<CalendarMonthOutlinedIcon className="detail-icon-" />
						{item.publicationDate}
					</div>
				)}

				{(item?.citationCount !== undefined || item?.influentialCitationCount !== undefined || item?.referenceCount !== undefined) && (
					<div className="detail-item">
						<FormatQuoteOutlinedIcon className="detail-icon-" />
						{item?.citationCount !== undefined && <span>Citations: {item.citationCount}&nbsp;&#x2022;&nbsp;</span>}
						{item?.influentialCitationCount !== undefined && <span>Influential Citations: {item.influentialCitationCount}&nbsp;&#x2022;&nbsp;</span>}
						{item?.referenceCount !== undefined && <span>References: {item.referenceCount}</span>}
					</div>
				)}

				{item?.s2FieldsOfStudy?.some((field) => field.source === "s2-fos-model") && (
					<div className="detail-item">
						<TopicOutlinedIcon className="detail-icon-" />
						{item.s2FieldsOfStudy.map((field, index) => field.source === "s2-fos-model" && <span key={index}>{field.category}&nbsp;&#x2022;&nbsp;</span>)}
					</div>
				)}

				{item?.tldr?.text && (
					<div style={{ padding: "0 10px" }}>
						<h2 className="detail-title">TLDR</h2>
						<p style={{ margin: 0, textAlign: "justify" }}>{item.tldr.text}</p>
						<br></br>
					</div>
				)}

				{item?.abstract && (
					<div style={{ padding: "0 10px" }}>
						<h2 className="detail-title">Abstract</h2>
						<p style={{ margin: 0, textAlign: "justify" }}>{item.abstract}</p>
						<br></br>
					</div>
				)}

				{item?.openAccessPdf?.url && (
					<div className="detail-item">
						<PictureAsPdfOutlinedIcon className="detail-icon-" />
						<a href={item.openAccessPdf.url.startsWith("http://") || item.openAccessPdf.url.startsWith("https://") ? item.openAccessPdf.url : `https://${item.openAccessPdf.url}`} target="_blank">
							{item.title + ".pdf"}
						</a>
					</div>
				)}
			</div>

			{item?.citations?.length > 0 && (
				<>
					<h4>Citations</h4>
					<div>
						{item.citations.map((citation, index) => (
							<Card key={index} category={"papers"} details={citation} verticalCreator={true} cardHeader={"PUBLICATION"} icons={icons} dotColor={"orange"} vertical_id={item.vertical_id} depth={1} />
						))}
					</div>
				</>
			)}

			{item?.references?.length > 0 && (
				<>
					<h4>References</h4>
					<div>
						{item.references.map((reference, index) => (
							<Card key={index} category={"papers"} details={reference} verticalCreator={true} cardHeader={"PUBLICATION"} icons={icons} dotColor={"orange"} vertical_id={item.vertical_id} depth={1} />
						))}
					</div>
				</>
			)}
		</>
	);
};
export default DetailCard;
