import { useContext } from "react";
import { Context } from "../AuthContext";
import { Navigate } from "react-router-dom";

export function Protected({ children, adminOnly = false }) {
	const { user, verticalJoined } = useContext(Context);
	if (user === "Admin") {
		return children;
	} else if (user === "Approved" && !adminOnly) {
		return children;
	} else if (user === "Approved" && adminOnly) {
		return <Navigate to={"/u/vertical/" + verticalJoined + "/home"} />;
	}
	// else if (user === "Created"){
	//     return <Navigate  to='/login?status=created' />
	// }
	else {
		return (window.location.href = "https://www.simple-platform.com/");
	}
}
