import AddForm from "../../components/Forms/AddForm";
import EditForm from "../../components/Forms/EditForm";
import auth from "../../firebase";
import { invalidUrlError, validateUrl } from "../../utils/functions";

const TutorialForm = ({ functionality }) => {
	const category = "tutorial";
	const fields = [
		{ name: "title", label: "Title", type: "text", required: true },
		{ name: "instructor", label: "Instructor", type: "text", required: true },
		{ name: "price", label: "Price", type: "text", required: false, placeholder: "$120" },
		{ name: "published_date", label: "Published Date", type: "date", required: true },
		{ name: "last_updated_date", label: "Last Updated Date", type: "date", required: false },
		{
			name: "tutorial_link",
			label: "Tutorial Link",
			type: "text",
			required: true,
			placeholder: "https://www.udemy.com/course/design-and-develop-website-with-html5-and-css3",
			pattern: validateUrl,
			title: invalidUrlError
		},
		{ name: "overview", label: "Overview", type: "text", required: true, text_area: true },
		{ name: "course_content", label: "Course Content", type: "text", required: false, text_area: true },
		{ name: "reqirements", label: "Requirements", type: "text", required: false, text_area: true },
		{ name: "description", label: "Description", type: "text", required: false, text_area: true }
	];

	const user = auth.currentUser;
	const uid = user.uid;

	return functionality === "add" ? <AddForm category={category} fields={fields} user_uid={uid} /> : <EditForm category={category} fields={fields} />;
};
export default TutorialForm;
