import EditForm from "../../components/Forms/EditForm";
import AddForm from "../../components/Forms/AddForm";
import { invalidUrlError, validateUrl } from "../../utils/functions";

const FundingForm = ({ functionality }) => {
	const category = "grant";
	const fields = [
		{ name: "grant_types", label: "Category", type: "text", required: true, placeholder: "Governmental/Research/Equipment/Other" },
		{ name: "OpportunityTitle", label: "Title", type: "text", required: true },
		{ name: "CloseDate", label: "Close Date", type: "date" },
		{ name: "AgencyName", label: "Agency Name", type: "text" },
		{ name: "AwardCeiling", label: "Award Ceiling", type: "text", placeholder: "$100,000" },
		{ name: "AwardFloor", label: "Award Floor", type: "text", placeholder: "$100,000" },
		{ name: "ExpectedNumberOfAwards", label: "Expected Number Of Awards", type: "number" },
		{ name: "EstimatedTotalProgramFunding", label: "Estimated Total Program Funding", type: "text", placeholder: "$100,000" },
		{ name: "PostDate", label: "Post Date", type: "date" },
		{ name: "LastUpdatedDate", label: "Last Updated Date", type: "date" },
		{ name: "ArchiveDate", label: "Archive Date", type: "date" },
		{ name: "EstimatedAwardDate", label: "Estimated Award Date", type: "date" },
		{ name: "EstimatedProjectStartDate", label: "Estimated Project Start Date", type: "date" },
		{ name: "activity_locations", label: "Activity Locations", type: "text" },
		{ name: "AdditionalInformationURL", label: "Additional Information Url", type: "text", placeholder: "https://cieif.org/guidelines-for-grant-applicants/", pattern: validateUrl, title: invalidUrlError },
		{ name: "GrantorContactName", label: "Grantor Contact Name", type: "text" },
		{ name: "GrantorContactEmail", label: "Grantor Contact Email", type: "email" },
		{ name: "GrantorContactPhoneNumber", label: "Grantor Contact Phone Number", type: "text" },
		{ name: "GrantorContactText", label: "Grantor Contact Text", type: "text", text_area: true },
		{ name: "CFDANumbers", label: "CFDA Numbers", type: "text" },
		{ name: "grant_source_url", label: "Grant Url", type: "text", placeholder: "https://grants.gov/search-results-detail/355599", pattern: validateUrl, title: invalidUrlError },
		{ name: "Description", label: "Description", type: "text", text_area: true, rows: "12", required: true },
		{ name: "EligibleApplicants", label: "Eligible Applicants", type: "text" },
		{ name: "applicant_locations", label: "Applicant Locations", type: "text" },
		{ name: "applicant_types", label: "Applicant Types", type: "text" },
		{ name: "AdditionalInformationOnEligibility", label: "Additional Information On Eligibility", type: "text", text_area: true, rows: "12" },
		{ name: "submission_info", label: "Submission Information", type: "text", text_area: true, rows: "12" },
		{ name: "amount_info", label: "Amount Information", type: "text", text_area: true, rows: "12" },
		{ name: "support_info", label: "Support Information", type: "text", text_area: true, rows: "12" }
	];

	return functionality === "add" ? <AddForm category={category} fields={fields} /> : <EditForm category={category} fields={fields} />;
};
export default FundingForm;
