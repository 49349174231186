import List from "../../components/List/List";
import { categories } from "../../utils/cardInfo";

const PeoplePage = () => {
	return (
		<>
			<List category={"people"} heading={"People"} cardHeader={categories.people.cardHeader} icons={categories.people.icons} urlIcons={categories.people.urlIcons} dotColor={categories.people.dotColor} />
		</>
	);
};
export default PeoplePage;
