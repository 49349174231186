import "./Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import auth from "../../firebase";
import { signOut } from "firebase/auth";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import SearchBar from "../SearchBar/SearchBar";
import Stepper from "../Stepper";

const Navbar = ({ verticalEditor }) => {
	const { vertical_id } = useParams();

	const navigate = useNavigate();
	const location = useLocation();
	const [openMenu, setOpenMenu] = useState(false);
	const handleMenu = () => {
		setOpenMenu(true);
	};

	const handleClose = () => {
		setOpenMenu(false);
	};

	const handleLogout = () => {
		signOut(auth)
			.then(() => {
				// Sign-out successful.
				window.location.href = "https://www.simple-platform.com/";
			})
			.catch((error) => {
				// An error happened.
			});
	};

	let navigateTo;
	let homePage;
	let profilePage;
	let invitePage;
	if (!verticalEditor) {
		navigateTo = location.pathname.split("/").slice(0, 4).join("/") + "/tryrnai";
		homePage = location.pathname.split("/").slice(0, 4).join("/");
		profilePage = location.pathname.split("/").slice(0, 4).join("/") + "/profile";
	} else {
		navigateTo = "/ve/tryrnai";
		homePage = "/ve/tryrnai";
		profilePage = "/ve/profile";
		invitePage = location.pathname.split("/").slice(0, 4).join("/") + "/inviteUser";
	}

	const handleJoinSlack = () => {
		window.open("https://join.slack.com/t/tspthesimpleplatform/shared_invite/zt-2n9i04xuc-gXVKkrZ8woUDqUesKTOUXQ", "_blank");
	};

	let image, style;
	if (vertical_id === "66aba1714138ba6f29f54279") {
		image = "/methane.png";
		style = { height: "50px", margin: "0 20px" };
		homePage = location.pathname.split("/").slice(0, 4).join("/") + "/home";
	} else {
		image = "/logo.svg";
		style = { height: "40px", margin: "0 10px" };
	}

	const theme = useTheme();

	const activeButtonStyle = {
		textTransform: "none",
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.primary.dark,
		"&:hover": {
			color: theme.palette.primary.contrastText,
			backgroundColor: theme.palette.primary.main
		}
	};

	const signupLabels = ["Invitation", "Create account", "Select role"];
	const forgotPasswordLabels = ["Enter email", "Enter new password", "Password set"];
	let labels = signupLabels;
	if (location.pathname.includes("password")) {
		labels = forgotPasswordLabels;
	}

	let line1b, circle2, line2a, line2b, circle3;
	if (location.pathname.includes("signup")) {
		line1b = true;
		circle2 = true;
	}

	if (location.pathname.includes("select-role") || location.pathname.includes("set-new-password")) {
		line1b = true;
		circle2 = true;
		line2a = true;
	}
	if (location.pathname.includes("select-sub-role") || location.pathname.includes("password-changed")) {
		line1b = true;
		circle2 = true;
		line2a = true;
		line2b = true;
		circle3 = true;
	}
	if (location.pathname.includes)
		return (
			<>
				<div className="navbar">
					<div style={{ width: "260px" }}>
						{/* <h4 className='navbar-header' onClick={() => navigate(homePage)}>The Simple Platform</h4> */}
						<Link to={homePage}>
							<img src={`${process.env.PUBLIC_URL + image}`} alt="TSP logo" style={style} />
						</Link>
					</div>
					{location.pathname.includes("vertical") && <SearchBar verticalEditor={verticalEditor} />}
					{!location.pathname.includes("login") && !location.pathname.includes("signup") && !location.pathname.includes("select-") && !location.pathname.includes("password") ? (
						<div className="navbar-items">
							<a href="https://faq.businesstech.fr/en/product/0-generic-faqs" target="_blank">
								<FontAwesomeIcon icon={faInfoCircle} color="black" className="clickable"></FontAwesomeIcon>
							</a>
							{location.pathname.includes("/ve/vertical/") && (
								<Button variant="outlined" sx={location.pathname.includes("inviteUser") ? activeButtonStyle : { textTransform: "none" }} onClick={() => navigate(invitePage)}>
									Invite Users
								</Button>
							)}
							<Button variant="outlined" sx={{ textTransform: "none" }} onClick={handleJoinSlack}>
								<img src={`${process.env.PUBLIC_URL}/slack.png`} alt="Slack logo" className="navbar-image" style={{ height: "20px" }} />
								Go To Community
							</Button>
							<Button variant="outlined" sx={location.pathname.includes("tryrnai") || location.pathname === "/ve" ? activeButtonStyle : { textTransform: "none" }} onClick={() => navigate(navigateTo)}>
								Try RNAI
							</Button>
							<IconButton size="large" onClick={handleMenu} color="inherit">
								<AccountCircle sx={{ fontSize: 30 }} />
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorOrigin={{
									vertical: "top",
									horizontal: "right"
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "right"
								}}
								open={openMenu}
								onClose={handleClose}>
								<MenuItem
									onClick={() => {
										navigate(profilePage);
										handleClose();
									}}>
									Profile
								</MenuItem>
								<MenuItem onClick={handleLogout}>Sign Out</MenuItem>
							</Menu>
						</div>
					) : (
						// <p style={{ margin: "auto" }}>Hi</p>
						(location.pathname.includes("signup") || location.pathname.includes("select") || location.pathname.includes("password")) && (
							<div style={{ position: "absolute", left: "50%", transform: "translateX(-50%)" }}>
								<Stepper label1={labels[0]} label2={labels[1]} label3={labels[2]} line1b={line1b} circle2={circle2} line2a={line2a} line2b={line2b} circle3={circle3} />
							</div>
						)
					)}
				</div>
				{location.pathname.includes("query") && (
					<div className="back" onClick={() => navigate(navigateTo)}>
						<ArrowBackIosIcon />
						<span>Back To My Queries</span>
					</div>
				)}
				{(location.pathname.includes("edit") || location.pathname.includes("add") || location.pathname.includes("/ve/profile") || location.pathname.includes("inviteUser")) && (
					<div className="back-button" onClick={() => navigate(-1)}>
						<ArrowBackIcon />
						<span>&nbsp;Back</span>
					</div>
				)}
				{(location.pathname.includes("select-role") || location.pathname.includes("select-sub-role")) && (
					<div className="back-button" style={{ color: "#1C1B1F" }} onClick={handleLogout}>
						<ArrowBackIcon />
						<span>&nbsp;Log Out</span>
					</div>
				)}
				{location.pathname.includes("password") && (
					<div className="back-button" style={{ color: "#1C1B1F" }} onClick={() => navigate("/login")}>
						<ArrowBackIcon />
						<span>&nbsp;Back to Sign In</span>
					</div>
				)}
			</>
		);
};
export default Navbar;
