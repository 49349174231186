import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import { useLocation } from "react-router-dom";

const AuthLayout = () => {
	const location = useLocation();
	let height, marginBottom;
	if (location.pathname.includes("role")) {
		height = "calc(100vh - 200px)";
		marginBottom = "80px";
	} else {
		height = "calc(100vh - 100px)";
		marginBottom = "0";
	}
	return (
		<>
			<Navbar layout="auth" />
			<div style={{ height: height, display: "flex", justifyContent: "center", alignItems: "center", marginTop: "80px", marginBottom: marginBottom }}>
				<Outlet />
			</div>
		</>
	);
};
export default AuthLayout;
