import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import "./Form.css";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ImageUpload from "../ImageUpload";
import auth from "../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { SuccessToast, ErrorToast } from "../Toast/toast";
import { useCategory } from "../../CategoryContext";

const FormWithImage = ({ category, fields, functionality = "edit" }) => {
	const { updateCount } = useCategory();
	const user = auth.currentUser;

	const theme = useTheme();
	const navigate = useNavigate();
	const location = useLocation();

	const { vertical_id, id } = useParams();
	const [loading, setLoading] = useState(true);
	const [submitting, setSubmitting] = useState(false);
	const [data, setData] = useState({});
	const [modifiedData, setModifiedData] = useState({});

	const [selectedFile, setSelectedFile] = useState(null);

	const handleFileSelect = (file) => {
		setSelectedFile(file);
		// setData['image_url'] = file;
	};

	const buttonStyle = {
		textTransform: "none",
		margin: "10px",
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		width: "30%",
		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
			borderColor: theme.palette.primary.dark
		}
	};

	useEffect(() => {
		const getItem = async () => {
			const item = await fetchItem();
			setData(item);
			setLoading(false);
		};
		if (functionality === "edit") {
			document.title = "Edit " + capitalizeFirstLetter(category) + " - The Simple Platform";
			getItem();
		} else {
			document.title = "Add " + capitalizeFirstLetter(category) + " - The Simple Platform";
		}
	}, []);

	const fetchItem = async () => {
		const token = await user.getIdToken();
		const url = process.env.REACT_APP_FLASK_WEBSERVER + "get_" + category + "_details?id=" + id + "&vertical_id=" + vertical_id;
		const res = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
		const data = await res.json();
		return data.data;
	};

	const submitForm = async (e) => {
		e.preventDefault();
		setSubmitting(true);

		const formData = new FormData();
		for (const key in modifiedData) {
			formData.append(key, modifiedData[key] ?? "");
		}

		if (selectedFile) {
			formData.append("image_file", selectedFile);
		} else if (selectedFile === "") {
			formData.append("image_url", "");
		}

		let response, _id;
		if (functionality === "edit") {
			response = await editItem(formData);
		} else {
			formData.append("vertical_id", vertical_id);
			[response, _id] = await addItem(formData);
		}

		if (response === 200) {
			if (functionality === "add") {
				let sideBarCategory = category === "person" ? "people" : category + "s";
				updateCount(sideBarCategory, 1);
				SuccessToast({ category: category, functionality: functionality, link: true, title: data.name, _id: _id });
				return navigate(location.pathname.slice(0, location.pathname.lastIndexOf("/")), { state: { animation: true } });
			} else {
				SuccessToast({ category: category, functionality: functionality });
				return navigate(location.pathname.slice(0, location.pathname.lastIndexOf("/edit")));
			}
		} else {
			ErrorToast({ category: category, functionality });
		}
		setSubmitting(false);
	};

	const addItem = async (item) => {
		const url = process.env.REACT_APP_FLASK_WEBSERVER + "add_" + category;
		const res = await fetch(url, {
			method: "POST",
			body: item
			// headers: {
			//     'Content-Type': 'application/json',
			// },
			// body: JSON.stringify(item)
		});
		const data = await res.json();
		return [res.status, data._id];
	};

	const editItem = async (item) => {
		const url = process.env.REACT_APP_FLASK_WEBSERVER + "edit_" + category + "?id=" + data._id;
		const res = await fetch(url, {
			method: "PATCH",
			body: item
		});
		await res.json();
		return res.status;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setModifiedData((prevData) => ({
			...prevData,
			[name]: value
		}));
		setData((data) => ({
			...data,
			[name]: value
		}));
	};

	function capitalizeFirstLetter(string) {
		if (!string) return string;
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	function getNestedProperty(obj, propertyPath) {
		const properties = propertyPath.split(".");
		return properties.reduce((prev, curr) => (prev && prev[curr] !== undefined ? prev[curr] : undefined), obj);
	}

	return !loading || functionality === "add" ? (
		<div>
			<form onSubmit={submitForm} className="add-form">
				<h2 className="heading-style">
					{capitalizeFirstLetter(functionality)} {capitalizeFirstLetter(category)}
				</h2>
				{fields.map((field) => (
					<div key={field.name} style={{ textAlign: "left" }}>
						<label className="label-style">
							{field.label}
							{field.required && <span style={{ color: "red" }}>&nbsp;*</span>}
						</label>
						{field.drop_down === true ? (
							<select id={field.name} name={field.name} className="input-style" value={data[field.name] || ""} onChange={handleChange} required={field.required} disabled={field.disabled || false}>
								<option value="" disabled>
									{field.placeholder || "Select an option"}
								</option>
								{field.options.map((option) => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</select>
						) : field.type === "image" ? (
							<ImageUpload existingImageUrl={data[field.name] || ""} onFileSelect={handleFileSelect} />
						) : !field.text_area ? (
							<input
								type={field.type}
								id={field.name}
								name={field.name}
								className="input-style"
								placeholder={field.placeholder || ""}
								pattern={field.pattern ? field.pattern : undefined}
								title={field.title ? field.title : undefined}
								required={field.required}
								value={!field.nested ? data[field.name] : getNestedProperty(data, field.name) || ""}
								onChange={handleChange}
								disabled={field.disabled || false}
							/>
						) : (
							<textarea
								type={field.type}
								id={field.name}
								name={field.name}
								className="input-style"
								rows={field.rows || "4"}
								required={field.required}
								value={!field.nested ? data[field.name] : getNestedProperty(data, field.name) || ""}
								onChange={handleChange}
							/>
						)}
					</div>
				))}

				{!submitting ? (
					<div>
						<Button type="submit" variant="outlined" disableRipple="true" sx={buttonStyle}>
							{functionality === "edit" ? "Save Edits" : `Add ${capitalizeFirstLetter(category)}`}
							{/* {capitalizeFirstLetter(functionality)} {capitalizeFirstLetter(category)} */}
						</Button>
						<Button type="button" variant="outlined" disableRipple={true} onClick={() => navigate(-1)}>
							Cancel
						</Button>
					</div>
				) : (
					<FontAwesomeIcon icon={faSpinner} spin size="2x" style={{ marginTop: "20px" }}></FontAwesomeIcon>
				)}
			</form>
		</div>
	) : (
		<div className="container">
			<FontAwesomeIcon icon={faSpinner} spin size="10x" color="whitesmoke"></FontAwesomeIcon>
		</div>
	);
};
export default FormWithImage;
